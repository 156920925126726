import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { MatDialog } from '@angular/material/dialog'

import { ToastrService } from 'ngx-toastr'

import { of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators'

import {
  loadAll,
  loadAllFailure,
  loadAllSuccess,
  loadOne,
  loadOneSuccess,
  loadOneFailure,
  add,
  addSuccess,
  addFailure,
  update,
  updateSuccess,
  updateFailure,
  autorizar,
  upsertEmitir,
  upsertEmitirSuccess,
  upsertEmitirFailure,
  autorizarSuccess,
  autorizarFailure,
  removeMany,
  removeManySuccess,
  removeManyFailure,
  cancelar,
  cancelarSuccess,
  cancelarFailure,
  updateColumns,
  gerarCteAnulacaoById,
  gerarCteAnulacaoByIdFailure,
  gerarCteAnulacaoByIdSuccess,
  gerarCteComplementarById,
  gerarCteComplementarByIdFailure,
  gerarCteComplementarByIdSuccess,
  gerarCteNormalById,
  gerarCteNormalByIdFailure,
  gerarCteNormalByIdSuccess,
  reenviarAverbacao,
  reenviarAverbacaoFailure,
  reenviarAverbacaoSuccess,
  addMany,
  addManyFailure,
  addManySuccess,
} from './ctes.actions'

import { TransmissionResponse } from '@core/types/return-transmission'
import { CtesService } from '@modules/ctes/services/ctes.service'
import { Cte } from '@modules/ctes/types/ctes.types'

@Injectable()
export class CtesEffects {
  getAll$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadAll),
      switchMap(action =>
        this._ctesService.getAllPaged(action.pagination, action.retornoAverbacao, action.resumido).pipe(
          map(retorno => loadAllSuccess({ returnPagination: retorno })),
          catchError(error => of(loadAllFailure({ error })))
        )
      )
    )
  )

  getById$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadOne),
      mergeMap(action => this._ctesService.getById(action.cteId)),
      map((cte: Cte) => loadOneSuccess({ cte })),
      catchError(error => of(loadOneFailure({ error })))
    )
  )

  create$ = createEffect(() =>
    this._actions.pipe(
      ofType(add),
      exhaustMap(action =>
        this._ctesService.create(action.cte).pipe(
          map(cte => addSuccess({ cte })),
          catchError(error => of(addFailure({ error })))
        )
      )
    )
  )

  createSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(addSuccess),
        tap(() => {
          this._toastrService.success('Criado com sucesso!', 'CT-e', {
            progressBar: true,
          })
        })
      ),
    { dispatch: false }
  )

  createFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(addFailure),
        tap(() => {
          this._toastrService.error('Não foi possível criar. Tente novamente!', 'CT-e')
        })
      ),
    { dispatch: false }
  )

  createMany$ = createEffect(() =>
    this._actions.pipe(
      ofType(addMany),
      exhaustMap(action =>
        this._ctesService.createMany(action.ctes).pipe(
          map(ctes => addManySuccess({ ctes })),
          catchError(error => of(addManyFailure({ error })))
        )
      )
    )
  )

  update$ = createEffect(() =>
    this._actions.pipe(
      ofType(update),
      exhaustMap(action =>
        this._ctesService.update(action.update.id, action.update.changes).pipe(
          map(() => updateSuccess({ update: action.update })),
          catchError(error => of(updateFailure({ error })))
        )
      )
    )
  )

  updateSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(updateSuccess),
        tap(() => {
          this._toastrService.success('Atualizado com sucesso!', 'CT-e', {
            progressBar: true,
          })
        })
      ),
    { dispatch: false }
  )

  updateFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(updateFailure),
        tap(() => {
          this._toastrService.error('Não foi possível atualizar. Tente novamente!', 'CT-e', {
            progressBar: true,
          })
        })
      ),
    { dispatch: false }
  )

  autorizar$ = createEffect(() =>
    this._actions.pipe(
      ofType(autorizar),
      exhaustMap(action =>
        this._ctesService.autorizar(action.ids).pipe(
          map((retorno: TransmissionResponse) => {
            if (retorno?.itens && retorno?.itens[0]?.status == '100') {
              this._toastrService.success(retorno?.itens[0]?.motivo, 'CT-e autorizado')
            } else if (retorno?.itens && retorno?.itens[0]?.status !== '100') {
              this._toastrService.error(retorno?.itens[0]?.motivo, 'CT-e rejeitado')
            } else if (!retorno?.itens) {
              this._toastrService.error(retorno?.erro, 'CT-e rejeitado')
            }
            return autorizarSuccess({ retornoCte: retorno })
          }),
          catchError(error => of(autorizarFailure({ error })))
        )
      )
    )
  )

  upsertEmitir$ = createEffect(() =>
    this._actions.pipe(
      ofType(upsertEmitir),
      exhaustMap(action =>
        this._ctesService.upsertEmitir(action.cte).pipe(
          map((retorno: { objeto: Cte; respostas: TransmissionResponse }) => {
            if (retorno?.respostas?.itens && retorno?.respostas?.itens[0]?.status == '100') {
              this._toastrService.success(retorno?.respostas?.itens[0]?.motivo, 'CT-e autorizado')
            } else if (retorno?.respostas?.itens && retorno?.respostas?.itens[0]?.status !== '100') {
              this._toastrService.error(retorno?.respostas?.itens[0]?.motivo, 'CT-e rejeitado')
            } else if (!retorno?.respostas?.itens) {
              this._toastrService.error(retorno?.respostas?.erro, 'CT-e rejeitado')
            }
            return upsertEmitirSuccess({ cte: retorno.objeto, retornoCte: retorno.respostas })
          }),
          catchError(error => {
            return of(upsertEmitirFailure({ error }))
          })
        )
      )
    )
  )

  cancelar$ = createEffect(() =>
    this._actions.pipe(
      ofType(cancelar),
      exhaustMap(action =>
        this._ctesService.cancelar(action.objCancelamento).pipe(
          map((retorno: TransmissionResponse) => {
            if (retorno?.itens && retorno?.itens[0]?.status == '135') {
              this._toastrService.success('CT-e CANCELADO!', 'CT-e cancelado')
            } else if (retorno?.itens && retorno?.itens[0]?.status !== '135') {
              this._toastrService.error(retorno?.itens[0]?.motivo, 'Cancelamento rejeitado')
            }
            return cancelarSuccess({ retornoCte: retorno })
          }),
          catchError(error => of(cancelarFailure({ error })))
        )
      )
    )
  )

  removeMany$ = createEffect(() =>
    this._actions.pipe(
      ofType(removeMany),
      exhaustMap(action =>
        this._ctesService.removeMany(action.ids).pipe(
          map((retorno: any) => {
            let ids: Array<{ id: number }> = action.ids
            let data: Array<{ id: number; resposta: string }> = retorno
            let keys: Array<number> = []

            if (data && data.length > 0) {
              data.forEach((dataValue: { id: number; resposta: string }, i: number) => {
                if (i >= 0) {
                  this._toastrService.error(`ID: ${dataValue.id} - ${dataValue.resposta} `, 'Exclusão')
                  ids = ids.filter(idsValue => idsValue.id !== dataValue.id)
                }
              })
            }

            if (ids && ids.length > 0) {
              ids.forEach((value: { id: number }, i: number) => {
                keys.push(value.id)
              })
            }

            return removeManySuccess({ ids: keys })
          }),
          catchError(error => of(removeManyFailure({ error })))
        )
      )
    )
  )

  removeSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(removeManySuccess),
        tap((data: { ids: number[] }) => {
          if (data.ids.length > 0)
            this._toastrService.success(`Os registros ${data.ids} foram excluidos com sucesso!`, 'Exclusão')
        })
      ),
    { dispatch: false }
  )

  reenviarAverbacao$ = createEffect(() =>
    this._actions.pipe(
      ofType(reenviarAverbacao),
      exhaustMap(({ id }) =>
        this._ctesService.reenviarAverbacao(id).pipe(
          map(averbacao => reenviarAverbacaoSuccess({ averbacao })),
          catchError(error => of(reenviarAverbacaoFailure({ error })))
        )
      )
    )
  )

  reenviarAverbacaoSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(reenviarAverbacaoSuccess),
        tap(({ averbacao }) => {
          this._toastrService.success(`CT-e id ${averbacao.cteId} averbado com sucesso.`, 'CT-e')
        })
      ),
    { dispatch: false }
  )

  reenviarAverbacaoFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(reenviarAverbacaoFailure),
        tap(() => {
          this._toastrService.error('Não foi possível averbar. Tente novamente!', 'CT-e')
        })
      ),
    { dispatch: false }
  )

  gerarCteNormalById$ = createEffect(() =>
    this._actions.pipe(
      ofType(gerarCteNormalById),
      exhaustMap(({ id }) =>
        this._ctesService.gerarCteNormal(id).pipe(
          map(cte => gerarCteNormalByIdSuccess({ cte })),
          catchError(error => of(gerarCteNormalByIdFailure({ error })))
        )
      )
    )
  )

  gerarCteNormalByIdSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(gerarCteNormalByIdSuccess),
        tap(({ cte }) => {
          this._toastrService.success(`CT-e id ${cte.id} gerado com sucesso.`, 'CT-e')
        })
      ),
    { dispatch: false }
  )

  gerarCteNormalByIdFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(gerarCteNormalByIdFailure),
        tap(() => {
          this._toastrService.error('Não foi possível gerar. Tente novamente!', 'CT-e')
        })
      ),
    { dispatch: false }
  )

  gerarCteAnulacaoById$ = createEffect(() =>
    this._actions.pipe(
      ofType(gerarCteAnulacaoById),
      exhaustMap(({ id }) =>
        this._ctesService.gerarCteAnulacao(id).pipe(
          map(cte => gerarCteAnulacaoByIdSuccess({ cte })),
          catchError(error => of(gerarCteAnulacaoByIdFailure({ error })))
        )
      )
    )
  )

  gerarCteAnulacaoByIdSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(gerarCteAnulacaoByIdSuccess),
        tap(({ cte }) => {
          this._toastrService.success(`CT-e id ${cte.id} gerado com sucesso.`, 'CT-e')
        })
      ),
    { dispatch: false }
  )

  gerarCteAnulacaoByIdFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(gerarCteAnulacaoByIdFailure),
        tap(() => {
          this._toastrService.error('Não foi possível gerar. Tente novamente!', 'CT-e')
        })
      ),
    { dispatch: false }
  )

  gerarCteComplementarById$ = createEffect(() =>
    this._actions.pipe(
      ofType(gerarCteComplementarById),
      exhaustMap(({ id }) =>
        this._ctesService.gerarCteComplementar(id).pipe(
          map(cte => gerarCteComplementarByIdSuccess({ cte })),
          catchError(error => of(gerarCteComplementarByIdFailure({ error })))
        )
      )
    )
  )

  gerarCteComplementarByIdSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(gerarCteComplementarByIdSuccess),
        tap(({ cte }) => {
          this._toastrService.success(`CT-e id ${cte.id} gerado com sucesso.`, 'CT-e')
        })
      ),
    { dispatch: false }
  )

  gerarCteComplementarByIdFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(gerarCteComplementarByIdFailure),
        tap(() => {
          this._toastrService.error('Não foi possível gerar. Tente novamente!', 'CT-e')
        })
      ),
    { dispatch: false }
  )

  constructor(private _actions: Actions, private _ctesService: CtesService, private _toastrService: ToastrService) {}
}
