import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'

import { Pagination, ReturnPagination, TransmissionResponse, ColumnsPage } from '@core/types'
import { AverbacaoCte, Cte } from '@modules/ctes/types/ctes.types'

/* LOAD os registros */
export const loadAll = createAction(
  '[CTES] Load',
  props<{ pagination: Pagination; retornoAverbacao?: boolean; resumido?: boolean }>()
)

export const loadAllSuccess = createAction('[CTES] Load Success', props<{ returnPagination: ReturnPagination }>())

export const loadAllFailure = createAction('[CTES] Load Failure', props<{ error: string }>())

/* LOAD ONE registro */
export const loadOne = createAction('[CTES] Load One ', props<{ cteId: number }>())

export const loadOneSuccess = createAction('[CTES] Load One Success', props<{ cte: Cte }>())

export const loadOneFailure = createAction('[CTES] Load One Failure', props<{ error: string }>())

/* Change columns */
export const updateColumns = createAction('[CTES] Update columns', props<{ columns: ColumnsPage[] }>())

/* Change columns */
export const updateStatusInutilizacao = createAction(
  '[CTES] Update status when inutilizado',
  props<{ numeroInicial: number; numeroFinal: number; serie: string }>()
)

/* CREATE um registro */
export const add = createAction('[CTES] Create', props<{ cte: Cte }>())

export const addSuccess = createAction('[CTES] Create Success', props<{ cte: Cte }>())

export const addFailure = createAction('[CTES] Create Failure', props<{ error: string }>())

/* CREATE diversos registros */
export const addMany = createAction('[CTES] Create Many', props<{ ctes: Cte[] }>())

export const addManySuccess = createAction('[CTES] Create Many Success', props<{ ctes: Cte[] }>())

export const addManyFailure = createAction('[CTES] Create Many Failure', props<{ error: string }>())

/* REMOVE vários registros */
export const removeMany = createAction('[CTES] Remove Many', props<{ ids: { id: number }[] }>())

export const removeManySuccess = createAction('[CTES] Remove Many Success', props<{ ids: number[] }>())

export const removeManyFailure = createAction('[CTES] Remove Many Failure', props<{ error: string }>())

/* UPDATE um registro */
export const update = createAction('[CTES] Update', props<{ update: Update<Cte> }>())

export const updateSuccess = createAction('[CTES] Update Success', props<{ update: Update<Cte> }>())

export const updateFailure = createAction('[CTES] Update Failure', props<{ error: string }>())

/* CREATE um CTe e já manda Autorizar */
export const upsertEmitir = createAction('[CTES] Upsert / Emitir', props<{ cte: Cte }>())

export const upsertEmitirSuccess = createAction(
  '[CTES] Upsert / Emitir Success',
  props<{ cte: Cte; retornoCte: TransmissionResponse }>()
)

export const upsertEmitirFailure = createAction('[CTES] Upsert / Emitir Failure', props<{ error: string }>())

/* AUTORIZAR um conhecimento */
export const autorizar = createAction('[CTES] Autorizar', props<{ ids: [{ id: number }] }>())

export const autorizarSuccess = createAction('[CTES] AutorizarSuccess', props<{ retornoCte: TransmissionResponse }>())

export const autorizarFailure = createAction('[CTES] Autorizar Failure', props<{ error: string }>())

/* CANCELAR um conhecimento */

export const cancelar = createAction(
  '[CTES] Cancelar',
  props<{ objCancelamento: { cteId: number; justificativa: string } }>()
)

export const cancelarSuccess = createAction('[CTES] Cancelar Success', props<{ retornoCte: TransmissionResponse }>())

export const cancelarFailure = createAction('[CTES] Cancelar  Failure', props<{ error: string }>())

/* Reenviar averbacao */
export const reenviarAverbacao = createAction('[CTES] Reenviar averbação', props<{ id: number }>())

export const reenviarAverbacaoSuccess = createAction(
  '[CTES] Reenviar averbação Success',
  props<{ averbacao: AverbacaoCte }>()
)

export const reenviarAverbacaoFailure = createAction('[CTES] Reenviar averbação Failure', props<{ error: string }>())

/* Copiar cte e gerar um novo */
export const gerarCteNormalById = createAction('[CTES] Gerar cte normal', props<{ id: number }>())

export const gerarCteNormalByIdSuccess = createAction('[CTES] Gerar cte normal Success', props<{ cte: Cte }>())

export const gerarCteNormalByIdFailure = createAction('[CTES] Gerar cte normal Failure', props<{ error: string }>())

/* Copiar cte e gerar um novo cte anulacao */
export const gerarCteAnulacaoById = createAction('[CTES] Gerar cte anulacao', props<{ id: number }>())

export const gerarCteAnulacaoByIdSuccess = createAction('[CTES] Gerar cte anulacao Success', props<{ cte: Cte }>())

export const gerarCteAnulacaoByIdFailure = createAction('[CTES] Gerar cte anulacao Failure', props<{ error: string }>())

/* Copiar cte e gerar um novo cte complementar*/
export const gerarCteComplementarById = createAction('[CTES] Gerar cte complementar', props<{ id: number }>())

export const gerarCteComplementarByIdSuccess = createAction(
  '[CTES] Gerar cte complementar Success',
  props<{ cte: Cte }>()
)

export const gerarCteComplementarByIdFailure = createAction(
  '[CTES] Gerar cte complementar Failure',
  props<{ error: string }>()
)
