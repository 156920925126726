export const isNaoAutorizado = (status: string | null | undefined) => {
  return status === null
}

export const isAutorizado = (status: string | null | undefined) => {
  return status === '100'
}

export const isCancelado = (status: string | null | undefined) => {
  return status === '101'
}

export const isInutilizado = (status: string | null | undefined) => {
  return status === '102'
}

export const isRejeitado = (status: string | null | undefined) => {
  return !isNaoAutorizado(status) && !isAutorizado(status) && !isCancelado(status) && !isInutilizado(status)
}
