import { createAction, props } from '@ngrx/store'
import { loginAuth, loginCredentials } from '@core/types'
import { Usuario } from '@modules/usuarios/usuarios.types'

export const login = createAction('[AUTH] Login', props<{ credentials: loginCredentials }>())

export const loginSuccess = createAction('[AUTH] Login Success', props<{ loginAuth: loginAuth }>())

export const loginFailure = createAction('[AUTH] Login Failure', props<{ error: string }>())

export const changeCompany = createAction('[AUTH] Trocar Empresa', props<{ id: number }>())

export const changeCompanySuccess = createAction('[AUTH] Trocar Empresa Success', props<{ loginAuth: loginAuth }>())

export const changeCompanyFailure = createAction('[AUTH] Trocar Empresa Failure', props<{ error: string }>())

export const refreshToken = createAction('[AUTH] Refresh Token')

export const refreshTokenSuccess = createAction('[AUTH] Refresh Token Success', props<{ loginAuth: loginAuth }>())

export const refreshTokenFailure = createAction('[AUTH] Refresh Token Failure', props<{ error: string }>())

export const logout = createAction('[AUTH] Logout')

export const updateUsuario = createAction('[AUTH] Change user group', props<{ usuario: Usuario }>())
