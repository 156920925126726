import { Injectable } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorService extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Itens por página: '
  override firstPageLabel = 'Primeira página'
  override lastPageLabel = 'Última página'
  override nextPageLabel = 'Próxima'
  override previousPageLabel = 'Anterior'

  constructor() {
    super()
  }

  override getRangeLabel = function (page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return '0 - 0 de ' + length
    }

    length = Math.max(length, 0)

    const startIndex = page * pageSize

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length
  }
}
