import { createSelector } from '@ngrx/store'
import { AuthState } from './auth.state'
import { selectAuthState } from '@core/root-store/app.selectors'
import { Usuario } from '@modules/usuarios/usuarios.types'
import { GrupoUsuario } from '@modules/grupo-usuario/grupo-usuario.types'

export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state)

export const selectIsAuthenticated = createSelector(selectAuthState, (state: AuthState) => state.isAuthenticated)

export const isLoading = createSelector(selectAuthState, (state: AuthState) => state.loading)

export const selectUser = createSelector(selectAuthState, (state: AuthState) => <Usuario>state?.user)

export const selectEmpresaConectada = createSelector(
  selectAuthState,
  (state: AuthState) => state.user?.empresaConectada
)

export const selectModulosAtuais = createSelector(selectEmpresaConectada, empresa => empresa?.modulosAtuais)

export const selectPermissoes = createSelector(selectAuthState, (state: AuthState) => state.user?.permissoes)

export const selectUserEmpresas = createSelector(
  selectAuthState,
  (state: AuthState) => <GrupoUsuario['empresasAcesso']>state.user?.permissoes.empresasAcesso
)

export const filteredUserCompanies = (search: string) =>
  createSelector(selectUserEmpresas, companies =>
    companies.filter(
      company => company.nome.toUpperCase().includes(search.toUpperCase()) || company.cnpj.includes(search)
    )
  )
