import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'

import { Pagination, ReturnPagination, ColumnsPage } from '@core/types'
import { Veiculo } from '@modules/veiculos/veiculos.types'

/* Carrega os registros */
export const loadAll = createAction('[VEICULO] Load', props<{ pagination: Pagination }>())

export const loadAllSuccess = createAction('[VEICULO] Load Success', props<{ returnPagination: ReturnPagination }>())

export const loadAllFailure = createAction('[VEICULO] Load Failure', props<{ error: string }>())

/* Carrega um registro */
export const loadOne = createAction('[VEICULO] Load One', props<{ veiculoId: number }>())

export const loadOneSuccess = createAction('[VEICULO] Load One Success', props<{ veiculo: Veiculo }>())

export const loadOneFailure = createAction('[VEICULO] Load One Failure', props<{ error: string }>())

/* Adiciona um registro */
export const add = createAction('[VEICULO] Create', props<{ veiculo: Veiculo }>())

export const addSuccess = createAction('[VEICULO] Create Success', props<{ veiculo: Veiculo }>())

export const addFailure = createAction('[VEICULO] Create Failure', props<{ error: string }>())

/* Remove um registro */
export const remove = createAction('[VEICULO] Remove', props<{ id: number }>())

export const removeSuccess = createAction('[VEICULO] Remove Success', props<{ id: number }>())

export const removeFailure = createAction('[VEICULO] Remove Failure', props<{ error: string }>())

/* Remove vários registros */
export const removeMany = createAction('[VEICULO] Remove Many', props<{ ids: { id: number }[] }>())

export const removeManySuccess = createAction('[VEICULO] Remove Many Success', props<{ ids: number[] }>())

export const removeManyFailure = createAction('[VEICULO] Remove Many Failure', props<{ error: string }>())

/* Atualiza um registro */
export const update = createAction('[VEICULO] Update', props<{ update: Update<Veiculo> }>())

export const updateSuccess = createAction('[VEICULO] Update Success', props<{ update: Update<Veiculo> }>())

export const updateFailure = createAction('[VEICULO] Update Failure', props<{ error: string }>())

/* Atualizar columns */
export const updateColumns = createAction('[VEICULO] Update columns', props<{ columns: ColumnsPage[] }>())
