import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core'

import { CommonModule, registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'

import { RouterModule } from '@angular/router'

import { MatMomentDateModule } from '@angular/material-moment-adapter'
/* import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter'
import ptBr from 'date-fns/locale/pt-BR' */

import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker'

import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { MatDialogModule } from '@angular/material/dialog'

import { EffectsModule } from '@ngrx/effects'
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import {
  metaReducers,
  reducers,
  fromRouter,
  fromAuth,
  fromSettings,
  fromParceiros,
  fromVeiculos,
  fromCtes,
  fromMdfes,
} from './root-store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'

import { environment } from '@environments/environment'

import { MatPaginatorService } from './services'

import { AuthInterceptor } from './interceptors'
import { ToastrModule } from 'ngx-toastr'
import { LayoutModule } from './layout/layout.module'
import { MatSnackBarModule } from '@angular/material/snack-bar'

registerLocaleData(localePt, 'pt')

/* let matLocale = ptBr
let dateInput = 'dd/MM/yyyy'

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: dateInput
  },
  display: {
    dateInput: dateInput,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
} */

@NgModule({
  declarations: [],
  imports: [
    /* Angular Modules */
    CommonModule,
    RouterModule,

    HttpClientModule,

    //MatDateFnsModule,
    MatMomentDateModule,

    MatSnackBarModule,
    MatDialogModule,

    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      progressAnimation: 'decreasing',
      newestOnTop: true,
      tapToDismiss: true,
      enableHtml: true,
      closeButton: true,
    }),

    /* NGRX Modules */
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      fromAuth.AuthEffects,
      fromSettings.SettingsEffects,
      fromParceiros.ParceirosEffects,
      fromVeiculos.VeiculosEffects,
      fromCtes.CtesEffects,
      fromMdfes.MdfesEffects,
    ]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'SYSCONTEC',
        }),
  ],
  exports: [
    /* Angular Modules */
    HttpClientModule,

    //MatDateFnsModule,
    MatMomentDateModule,
  ],
  providers: [
    /*

      Interceptadores

    */
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    /*

      Serializer responsavel pelo NGRX route store

    */
    { provide: RouterStateSerializer, useClass: fromRouter.CustomSerializer },

    /*

      Números do sistema formato brasileiro

    */
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },

    /*

      Formatação padrão para o form field

    */
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      } as MatFormFieldDefaultOptions,
    },

    /*

      Formataçaõ padrão para o componente paginator

    */
    { provide: MatPaginatorIntl, useClass: MatPaginatorService },
    /*

      FORMATAÇÕES DE DATA PARA O ANGULAR MATERIAL

    */
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },

    //MOMENT-JS
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['L'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'L',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },

    /* {
      provide: MAT_DATE_LOCALE,
      useValue: matLocale
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter
      deps: [MAT_DATE_LOCALE]
    } */
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule já foi instanciado. Importe-o somente em AppModule.')
    }
  }
}
