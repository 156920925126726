/* Angular */
import { Injectable } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
/* NGRX */
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
/* RXJS */
import { tap, withLatestFrom } from 'rxjs/operators'
/* Projeto */
import { LocalStorageService } from '@core/services'
import { updateTheme } from './settings.actions'
import { selectSettingsState } from '../app.selectors'
import { selectTheme } from './settings.selectors'
import { Style } from '@core/enums/style.enum'

@Injectable()
export class SettingsEffects {
  persistSettings = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTheme),
        withLatestFrom(this.store.pipe(select(selectSettingsState))),
        tap(([action, settings]) =>
          this.localStorageService.setItemLocalStorage(
            'settings',
            JSON.stringify({
              theme: settings.theme,
            })
          )
        )
      ),
    { dispatch: false }
  )

  /* Efeito responsavel por trocar o tema de todos os componentes da aplicação */
  /* Efeito necessario por causa dos componentes que ficam sobrepostos, como o DIALOG */
  /* Efeito é executado no inicio da aplicação e quando o tema é alterado */
  updateTheme$ = createEffect(
    () =>
      this.actions$.pipe(ofType(ROOT_EFFECTS_INIT, updateTheme)).pipe(
        withLatestFrom(this.store.pipe(select(selectTheme))),
        tap(([action, effectiveTheme]) => {
          const classList = this.overlayContainer.getContainerElement().classList
          let toRemove: string[] = []
          Object.values(Style)
            .filter(s => s !== effectiveTheme)
            .forEach(value => {
              toRemove = [...toRemove, ...Array.from(classList).filter((item: string) => item.includes(value))]
            })
          if (toRemove.length) {
            classList.remove(...toRemove)
          }
          classList.add(effectiveTheme)
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private overlayContainer: OverlayContainer,
    private localStorageService: LocalStorageService
  ) {}
}
