import { Injectable } from '@angular/core'
import { HttpBackend, HttpClient } from '@angular/common/http'

import { EMPTY, Observable } from 'rxjs'

import { City, FederalUnit, Country } from '@core/types'

export interface ViaCep {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

@Injectable({
  providedIn: 'root',
})
export class AddressApiService {
  readonly baseUrl: string = 'https://api-cidades.syscon.com.br'

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.http = new HttpClient(handler)
  }

  getAllFederalUnit(): Observable<FederalUnit[]> {
    return this.http.get<FederalUnit[]>('assets/json/estadosBrasileiros.json')
  }

  getCityByUf(uf: string): Observable<City[]> {
    return this.http.get<City[]>(`${this.baseUrl}/uf/${uf}`)
  }

  getAllCities(): Observable<City[]> {
    return this.http.get<City[]>(`${this.baseUrl}/cidades/all`)
  }

  getAllCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.baseUrl}/paises/all`)
  }

  searchCity(search: string): Observable<City[]> {
    return this.http.get<City[]>(`${this.baseUrl}/cidade/nome/pesquisa/${search}`)
  }

  searchCoutry(search: string): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.baseUrl}/paises/pesquisa/${search}`)
  }

  searchCep(cep: string): Observable<ViaCep> {
    cep = cep.replace(/\D/g, '')
    if (cep !== null && cep !== '') {
      const validaCep = /^[0-9]{8}$/

      if (validaCep.test(cep)) {
        return this.http.get<ViaCep>(`https://viacep.com.br/ws/${cep}/json/`)
      }
    }

    return EMPTY
  }
}
