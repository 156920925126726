import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { CoreModule } from '@core/core.module'

import { AppComponent } from './app.component'

import { routes } from './app.routing'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    /* Routing application */
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),

    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:1000',
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
