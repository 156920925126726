import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState } from '..'

import { VeiculosState } from './veiculos.state'
import { adapter } from './veiculos.reducer'

import { Veiculo } from '@modules/veiculos/veiculos.types'

import { Pagination } from '@core/types/pagination'

const { selectAll, selectEntities } = adapter.getSelectors()

export const selectState = createFeatureSelector<VeiculosState>('veiculos')

export const selectLoadingPage = createSelector(selectState, state => state.loadingList)

export const selectLoadingForm = createSelector(selectState, state => state.loadingForm)

export const selectError = createSelector(selectState, state => state.error)

export const selectPagination = createSelector(selectState, state => <Pagination>state.pagination)

export const selectTotalItens = createSelector(selectState, state => state.totalItens)

export const selectRecords = createSelector(selectState, selectAll)

export const selectRecordsOrdered = createSelector(selectState, selectRecords, (state, entities) => {
  const sort: string = state?.pagination?.sort || ''
  const order: string = state?.pagination?.order || ''

  return entities.sort((a: Veiculo | any, b: Veiculo | any) => {
    if (order === 'asc') {
      return ('' + a[sort]).localeCompare('' + b[sort], undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    } else {
      return ('' + b[sort]).localeCompare('' + a[sort], undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    }
  })
})

export const selectAllEntities = createSelector(selectState, selectEntities)

export const selectById = (id: number) => createSelector(selectAllEntities, entities => <Veiculo>entities[id])

export const selectColumns = createSelector(selectPagination, pagination => pagination.columns)
