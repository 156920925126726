import { Injectable } from '@angular/core'

const APP_PREFIX = 'st_'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      return Object.keys(localStorage).reduce((state: any, storageKey) => {
        if (storageKey.includes(APP_PREFIX)) {
          const stateKeys = storageKey
            .replace(APP_PREFIX, '')
            .toLowerCase()
            .split('.')
            .map(key =>
              key
                .split('-')
                .map((token, index) => (index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1)))
                .join('')
            )
          let currentStateRef = state
          stateKeys.forEach((key, index) => {
            if (index === stateKeys.length - 1) {
              currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey) || '{}')
              return
            }
            currentStateRef[key] = currentStateRef[key] || {}
            currentStateRef = currentStateRef[key]
          })
        }
        return state
      }, {})
    }, {})
  }

  setItemLocalStorage(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, value)
  }

  getItemLocalStorage(key: string) {
    return localStorage.getItem(`${APP_PREFIX}${key}`)
  }

  removeItemLocalStorage(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`)
  }

  setItemSessionStorage(key: string, value: any) {
    sessionStorage.setItem(`${APP_PREFIX}${key}`, value)
  }

  getItemSessionStorage(key: string) {
    return sessionStorage.getItem(`${APP_PREFIX}${key}`)
  }

  removeItemSessionStorage(key: string) {
    sessionStorage.removeItem(`${APP_PREFIX}${key}`)
  }
}
