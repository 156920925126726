import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment } from '@environments/environment'

import { LocalStorageService } from '@core/services'
import { loginAuth, loginCredentials, registerAuth } from '@core/types'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly token = 'tbearer'
  private readonly tokenRefresh = 'rbearer'

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  login(credentials: loginCredentials): Observable<loginAuth> {
    return this.http.post<loginAuth>(`${environment.baseUrl}/v1/login`, credentials)
  }

  register(credentials: registerAuth): Observable<registerAuth> {
    return this.http.post<registerAuth>(`${environment.baseUrl}/v1/cadastrar`, credentials)
  }

  refreshToken(): Observable<loginAuth> {
    return this.http.post<loginAuth>(`${environment.baseUrl}/v1/refresh`, {
      tokenRefresh: this.getRefreshToken(),
    })
  }

  changeCompany(id: number): Observable<loginAuth> {
    return this.http.get<loginAuth>(`${environment.baseUrl}/v1/mudarEmpresa/${id}`)
  }

  getJwtToken(): string | null {
    return this.localStorageService.getItemSessionStorage(this.token)
  }

  getRefreshToken(): string | null {
    return this.localStorageService.getItemSessionStorage(this.tokenRefresh)
  }

  storeTokens(token: string, tokenRefresh: string) {
    this.localStorageService.setItemSessionStorage(this.token, token)
    this.localStorageService.setItemSessionStorage(this.tokenRefresh, tokenRefresh)
  }

  removeTokens() {
    this.localStorageService.removeItemSessionStorage(this.token)
    this.localStorageService.removeItemSessionStorage(this.tokenRefresh)
  }
}
