export enum Modules {
  Paineis = 'paineis',
  Parceiros = 'parceiros',
  Veiculos = 'veiculos',
  Cte = 'cte',
  Mdfe = 'mdfe',
  Relatorios = 'relatorios',
  Configuracoes = 'configuracoes',
  Serie = 'serie',
  Email = 'email',
  Seguro = 'seguro',
  Certificado = 'certificado',
  UnidadesMedida = 'unidadesMedida',
  Cfop = 'cfop',
  Empresas = 'empresas',
  Usuarios = 'usuarios',
  GrupoUsuario = 'grupoUsuario',
  Inutilizacao = 'inutilizacao',
  AgenciaBancaria = 'agenciaBancaria',
}
