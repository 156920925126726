import { createReducer, on } from '@ngrx/store'

import { Style } from '@core/enums'

import { updateTheme } from './settings.actions'
import { SettingsState } from './settings.state'

export const initialState: SettingsState = {
  theme: Style.light,
}

export const reducer = createReducer(
  initialState,
  on(updateTheme, (state, action) => ({ ...state, theme: action.theme }))
)
