import { EntityState } from '@ngrx/entity'
import { Pagination } from '@core/types'
import { Mdfe } from '@modules/mdfes/mdfes.types'

export interface MdfesState extends EntityState<Mdfe> {
  totalItens: number
  loadingList: boolean
  loadingForm: boolean
  pagination: Pagination
  error: string | undefined
}
