import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'

import { Actions, createEffect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators'

import { ToastrService } from 'ngx-toastr'

import {
  add,
  addFailure,
  addSuccess,
  loadAll,
  loadAllFailure,
  loadAllSuccess,
  loadOne,
  loadOneFailure,
  loadOneSuccess,
  removeMany,
  removeManySuccess,
  removeManyFailure,
  update,
  updateFailure,
  updateSuccess,
} from './veiculos.actions'
import { VeiculosService } from '@modules/veiculos/services/veiculos.service'

@Injectable()
export class VeiculosEffects {
  getAll$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadAll),
      switchMap(action =>
        this._veiculosService.getAllPaged(action.pagination).pipe(
          map(returnPagination => loadAllSuccess({ returnPagination })),
          catchError(error => of(loadAllFailure({ error })))
        )
      )
    )
  )

  getById$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadOne),
      mergeMap(action => this._veiculosService.getById(action.veiculoId)),
      map(veiculo => loadOneSuccess({ veiculo })),
      catchError(error => of(loadOneFailure({ error })))
    )
  )

  create$ = createEffect(() =>
    this._actions.pipe(
      ofType(add),
      exhaustMap(action =>
        this._veiculosService.create(action.veiculo).pipe(
          map(veiculo => addSuccess({ veiculo })),
          catchError((error: HttpErrorResponse) =>
            of(
              addFailure({
                error: error.error?.message || 'Não foi possível cadastrar o Veículo. Tente mais tarde!',
              })
            )
          )
        )
      )
    )
  )

  createSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(addSuccess),
        tap(() => {
          this._toastrService.success('Criado com sucesso!', 'Veículo')
        })
      ),
    { dispatch: false }
  )

  createFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(addFailure),
        tap(({ error }) => {
          this._toastrService.error(error, 'Veículo')
        })
      ),
    { dispatch: false }
  )

  update$ = createEffect(() =>
    this._actions.pipe(
      ofType(update),
      exhaustMap(action =>
        this._veiculosService.update(action.update.id, action.update.changes).pipe(
          map(response => updateSuccess({ update: { id: response?.id || 0, changes: response } })),
          catchError(error =>
            of(
              updateFailure({
                error: error.error?.message || 'Não foi possível atualizar o Veículo. Tente mais tarde!',
              })
            )
          )
        )
      )
    )
  )

  updateSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(updateSuccess),
        tap(() => {
          this._toastrService.success('Atualizado com sucesso!', 'Veículo')
        })
      ),
    { dispatch: false }
  )

  updateFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(updateFailure),
        tap(({ error }) => {
          this._toastrService.error(error, 'Veículo')
        })
      ),
    { dispatch: false }
  )

  removeMany$ = createEffect(() =>
    this._actions.pipe(
      ofType(removeMany),
      exhaustMap(action =>
        this._veiculosService.removeMany(action.ids).pipe(
          map((retorno: any) => {
            let ids: Array<{ id: number }> = action.ids
            let data: Array<{ id: number; resposta: string }> = retorno
            let keys: Array<number> = []

            if (data && data.length > 0) {
              data.forEach((dataValue: { id: number; resposta: string }, i: number) => {
                if (i >= 0) {
                  this._toastrService.error(`ID: ${dataValue.id} - ${dataValue.resposta} `, 'Exclusão')
                  ids = ids.filter(idsValue => idsValue.id !== dataValue.id)
                }
              })
            }

            if (ids && ids.length > 0) {
              ids.forEach((value: { id: number }, i: number) => {
                keys.push(value.id)
              })
            }

            return removeManySuccess({ ids: keys })
          }),
          catchError(error => of(removeManyFailure({ error })))
        )
      )
    )
  )

  removeSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(removeManySuccess),
        tap((data: { ids: number[] }) => {
          if (data.ids.length > 0)
            this._toastrService.success(`Os registros ${data.ids} foram excluidos com sucesso!`, 'Exclusão')
        })
      ),
    { dispatch: false }
  )

  constructor(
    private _actions: Actions,
    private _veiculosService: VeiculosService,
    private _toastrService: ToastrService
  ) {}
}
