import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'

import {
  add,
  addFailure,
  addSuccess,
  loadAll,
  loadAllFailure,
  loadAllSuccess,
  loadOne,
  loadOneFailure,
  loadOneSuccess,
  removeMany,
  removeManyFailure,
  removeManySuccess,
  update,
  updateFailure,
  updateSuccess,
  autorizar,
  autorizarSuccess,
  autorizarFailure,
  upsertEmitir,
  upsertEmitirSuccess,
  upsertEmitirFailure,
  cancelar,
  cancelarSuccess,
  cancelarFailure,
  updateColumns,
  gerarCteAnulacaoById,
  gerarCteAnulacaoByIdFailure,
  gerarCteAnulacaoByIdSuccess,
  gerarCteComplementarById,
  gerarCteComplementarByIdFailure,
  gerarCteComplementarByIdSuccess,
  gerarCteNormalById,
  gerarCteNormalByIdFailure,
  gerarCteNormalByIdSuccess,
  reenviarAverbacao,
  reenviarAverbacaoFailure,
  reenviarAverbacaoSuccess,
  addMany,
  addManyFailure,
  addManySuccess,
} from './ctes.actions'

import { Cte } from '@modules/ctes/types/ctes.types'
import { ColumnsPage } from '@core/types'
import { CtesState, updateStatusInutilizacao } from '.'
import { isNaoAutorizado, isRejeitado } from '@shared/utils/cte'

export const adapter: EntityAdapter<Cte> = createEntityAdapter<Cte>()

export const initialColumns: ColumnsPage[] = [
  {
    label: 'Data de emissão',
    name: 'dataemissao',
    show: true,
  },
  {
    label: 'Série',
    name: 'serie',
    show: true,
  },
  {
    label: 'Número',
    name: 'numero',
    show: true,
  },
  {
    label: 'Pagador do Frete',
    name: 'pagopor',
    show: false,
  },
  {
    label: 'Local início prestação',
    name: 'localinicioprestacao',
    show: true,
  },
  {
    label: 'Local término prestação',
    name: 'localterminoprestacao',
    show: true,
  },

  {
    label: 'Total prestação',
    name: 'totalprestacao',
    show: false,
  },

  {
    label: 'Valor a receber',
    name: 'valorreceber',
    show: true,
  },
  {
    label: 'Status',
    name: 'status',
    show: true,
  },
  {
    label: 'Averbação',
    name: 'averbacao',
    show: true,
  },
  {
    label: 'Tipo CT-e',
    name: 'tipocte',
    show: false,
  },
  {
    label: 'Tipo serviço',
    name: 'tiposervico',
    show: false,
  },

  {
    label: 'Remetente',
    name: 'remetente',
    show: false,
  },
  {
    label: 'Destinatário',
    name: 'destinatario',
    show: false,
  },
  {
    label: 'Expedidor',
    name: 'expedidor',
    show: false,
  },
  {
    label: 'Recebedor',
    name: 'recebedor',
    show: false,
  },
  {
    label: 'Tomador',
    name: 'tomador',
    show: false,
  },
]

export const initialState: CtesState = adapter.getInitialState({
  totalItens: 0,
  loadingList: false,
  loadingForm: false,
  pagination: {
    sort: 'id',
    order: 'desc',
    page: 0,
    limit: 20,
    columns: initialColumns,
    arraySearch: [],
    arraySearchAdvanced: [],
  },
  error: undefined,
})

export const reducer = createReducer(
  initialState,
  on(loadAll, (state, action) => ({
    ...state,
    loadingList: true,
    pagination: { ...state.pagination, ...action.pagination },
    error: undefined,
  })),
  on(loadAllSuccess, (state, { returnPagination }) => {
    return adapter.setAll(returnPagination.itens, {
      ...state,
      totalItens: returnPagination.quantidadeRegistros,
      loadingList: false,
      error: undefined,
    })
  }),
  on(loadAllFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Carregamento de um registro */
  on(loadOne, (state, action) => ({
    ...state,
    error: undefined,
  })),
  on(loadOneSuccess, (state, { cte }) => {
    return adapter.setOne(cte, {
      ...state,
      error: undefined,
    })
  }),
  on(loadOneFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(updateColumns, (state, { columns }) => ({
    ...state,
    pagination: { ...state.pagination, ...{ columns } },
  })),

  on(updateStatusInutilizacao, (state, { numeroInicial, numeroFinal, serie }) => {
    return adapter.map(
      (registro: Cte) => {
        if (
          (isNaoAutorizado(registro.statusSefaz) || isRejeitado(registro.statusSefaz)) &&
          registro.serie.serie === serie &&
          registro.numero >= numeroInicial &&
          registro.numero <= numeroFinal
        ) {
          return { ...registro, ...{ statusSefaz: '102' } }
        }

        return registro
      },
      { ...state }
    )
  }),

  /* Adicionando um registro */
  on(add, (state, action) => ({ ...state, loadingForm: true, error: undefined })),
  on(addSuccess, (state, { cte }) => {
    if (state.totalItens >= state.pagination.limit) {
      state = adapter.removeOne(state.ids.slice(-1)[0] as number, state)
    }

    return adapter.addOne(cte, {
      ...state,
      totalItens: state.totalItens + 1,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(addFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  })),

  /* Adicionando vários registros */
  on(addMany, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(addManySuccess, (state, { ctes }) => {
    return adapter.addMany(ctes, {
      ...state,
      loadingForm: false,
      totalItens: state.totalItens + ctes.length,
      error: undefined,
    })
  }),
  on(addManyFailure, (state, { error }) => ({
    ...state,
    loadingForm: false,
    error: error,
  })),

  /* Removendo vários registros */
  on(removeMany, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(removeManySuccess, (state, { ids }) => {
    return adapter.removeMany(ids, {
      ...state,
      loadingList: false,
      totalItens: state.totalItens - ids.length,
      error: undefined,
    })
  }),
  on(removeManyFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Atualizando um registro */
  on(update, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(updateSuccess, (state, { update }) => {
    return adapter.updateOne(update, {
      ...state,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(updateFailure, (state, { error }) => ({
    ...state,
    loadingForm: false,
    error: error,
  })),

  /* Autorizando um CTe */
  on(autorizar, (state, action) => ({
    ...state,
    loadingForm: true,
    loadingList: true,
    error: undefined,
  })),

  on(autorizarSuccess, (state, { retornoCte }) => {
    return adapter.map(
      (registro: Cte) => {
        if (retornoCte?.itens && retornoCte?.itens?.length > 0) {
          const index = retornoCte.itens.findIndex(v => v.id === registro.id)

          if (index >= 0)
            return {
              ...registro,
              statusSefaz: retornoCte.itens[index]?.status,
              chave: retornoCte.itens[index]?.chave,
            }

          return registro
        }

        return registro
      },
      { ...state, loadingForm: false, loadingList: false, error: undefined }
    )
  }),

  on(autorizarFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    loadingList: false,
    error: action.error,
  })),

  /* Cancelar um CT-e */
  on(cancelar, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),

  on(cancelarSuccess, (state, { retornoCte }) => {
    return adapter.map(
      (registro: Cte) => {
        if (retornoCte?.itens && retornoCte?.itens?.length > 0) {
          const index = retornoCte.itens.findIndex(v => v.id === registro.id)

          if (index >= 0 && retornoCte.itens[index].status == '135')
            return {
              ...registro,
              statusSefaz: '101',
              statusAverbacao: retornoCte.itens[index].statusAverbacao,
            }

          return registro
        }

        return registro
      },
      { ...state, loadingList: false, error: undefined }
    )
  }),

  on(cancelarFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  })),

  /* Criar / Atualizar e Emitir CT-e */
  on(upsertEmitir, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(upsertEmitirSuccess, (state, { cte }) => {
    return adapter.upsertOne(cte, { ...state, loadingForm: false, error: undefined })
  }),
  on(upsertEmitirFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  })),

  /* Reenviar averbação */
  on(reenviarAverbacao, (state, action) => ({ ...state, loadingList: true, error: undefined })),
  on(reenviarAverbacaoSuccess, (state, { averbacao }) => {
    return adapter.map(
      (registro: Cte) => {
        if (registro.id === averbacao.cteId)
          return Object.assign({}, registro, { statusAverbacao: averbacao.statusAverbacao })
        else return registro
      },
      { ...state, loadingList: false, error: undefined }
    )
  }),
  on(reenviarAverbacaoFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  })),

  /* Copiar cte e gerar um novo normal */
  on(gerarCteNormalById, (state, action) => ({ ...state, loadingList: true, error: undefined })),
  on(gerarCteNormalByIdSuccess, (state, { cte }) => {
    if (state.pagination.page === 0)
      return adapter.addOne(cte, {
        ...state,
        loadingList: false,
        error: undefined,
      })
    else return { ...state, loadingList: false }
  }),
  on(gerarCteNormalByIdFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  })),

  /* Copiar cte e gerar um novo anulação */
  on(gerarCteAnulacaoById, (state, action) => ({ ...state, loadingList: true, error: undefined })),
  on(gerarCteAnulacaoByIdSuccess, (state, { cte }) => {
    if (state.pagination.page === 0)
      return adapter.addOne(cte, {
        ...state,
        loadingList: false,
        error: undefined,
      })
    else return { ...state, loadingList: false }
  }),
  on(gerarCteAnulacaoByIdFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  })),

  /* Copiar cte e gerar um novo complementar */
  on(gerarCteComplementarById, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(gerarCteComplementarByIdSuccess, (state, { cte }) => {
    if (state.pagination.page === 0)
      return adapter.addOne(cte, {
        ...state,
        loadingList: false,
        error: undefined,
      })
    else return { ...state, loadingList: false }
  }),
  on(gerarCteComplementarByIdFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  }))
)
