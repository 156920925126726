import { Routes } from '@angular/router'
import { AuthGuard } from '@core/guards'

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./core/layout/layout.module').then(m => m.LayoutModule),
  },
]
