import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'

import {
  add,
  addSuccess,
  addFailure,
  loadAll,
  loadAllSuccess,
  loadAllFailure,
  loadOne,
  loadOneSuccess,
  loadOneFailure,
  update,
  updateSuccess,
  updateFailure,
  remove,
  removeSuccess,
  removeFailure,
  removeMany,
  removeManySuccess,
  removeManyFailure,
  updateColumns,
} from './veiculos.actions'

import { ColumnsPage } from '@core/types'
import { VeiculosState } from './veiculos.state'
import { Veiculo } from '@modules/veiculos/veiculos.types'

export const adapter: EntityAdapter<Veiculo> = createEntityAdapter<Veiculo>()

export const initialColumns: ColumnsPage[] = [
  { name: 'placa', label: 'Placa', show: true },
  { name: 'configplaca', label: 'Configuração placa', show: true },
  { name: 'proprietarionome', label: 'Proprietário', show: true },
  { name: 'empresaproprietarianome', label: 'Empresa Proprietária', show: true },
  { name: 'motoristanome', label: 'Motorista', show: false },
  { name: 'segundomotoristanome', label: 'Segundo Motorista', show: false },
  { name: 'renavam', label: 'RENAVAM', show: false },
  { name: 'tiporodado', label: 'Tipo Rodado', show: false },
  { name: 'tipocarroceria', label: 'Tipo Carroceria', show: false },
  { name: 'tipofrota', label: 'Tipo Frota', show: false },
  { name: 'dataaquisicao', label: 'Data Aquisição', show: false },
  { name: 'datavenda', label: 'Data Venda', show: false },
  { name: 'cor', label: 'COR', show: false },
  { name: 'numerofrota', label: 'Número da frota', show: false },
  { name: 'codigofipe', label: 'Código FIPE', show: false },
  { name: 'situacao', label: 'Situação', show: true },
]

export const initialState: VeiculosState = adapter.getInitialState({
  totalItens: 0,
  loadingForm: false,
  loadingList: false,
  pagination: {
    sort: 'id',
    order: 'desc',
    page: 0,
    limit: 50,
    columns: initialColumns,
    arraySearch: [],
    arraySearchAdvanced: [],
  },
  error: undefined,
})

export const reducer = createReducer(
  initialState,
  /* Carregamento dos registros */
  on(loadAll, (state, action) => ({
    ...state,
    loadingList: true,
    pagination: { ...state.pagination, ...action.pagination },
    error: undefined,
  })),
  on(loadAllSuccess, (state, { returnPagination }) => {
    return adapter.setAll(returnPagination.itens, {
      ...state,
      totalItens: returnPagination.quantidadeRegistros,
      loadingList: false,
      error: undefined,
    })
  }),
  on(loadAllFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Carregamento de um registro */
  on(loadOne, (state, action) => ({
    ...state,
    error: undefined,
  })),
  on(loadOneSuccess, (state, { veiculo }) => {
    return adapter.setOne(veiculo, {
      ...state,
      error: undefined,
    })
  }),
  on(loadOneFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  /* Adicionando um registro na primeira posição da lista*/
  on(add, (state, action) => ({ ...state, loadingForm: true, error: undefined })),
  on(addSuccess, (state, { veiculo }) => {
    if (state.totalItens >= state.pagination.limit) {
      state = adapter.removeOne(state.ids.slice(-1)[0] as number, state)
    }

    return adapter.addOne(veiculo, {
      ...state,
      totalItens: state.totalItens + 1,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(addFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  })),

  /* Removendo um registro */
  on(remove, (state, action) => ({
    ...state,
    error: undefined,
  })),
  on(removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      error: undefined,
    })
  }),
  on(removeFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  /* Removendo vários registros */
  on(removeMany, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(removeManySuccess, (state, { ids }) => {
    return adapter.removeMany(ids, {
      ...state,
      loadingList: false,
      totalItens: state.totalItens - ids.length,
      error: undefined,
    })
  }),
  on(removeManyFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Atualizando um registro */
  on(update, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(updateSuccess, (state, { update }) => {
    return adapter.updateOne(update, {
      ...state,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(updateFailure, (state, { error }) => ({
    ...state,
    loadingForm: false,
    error: error,
  })),
  on(updateColumns, (state, { columns }) => ({
    ...state,
    pagination: { ...state.pagination, ...{ columns } },
  }))
)
