import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment } from '@environments/environment'
import { Pagination, ReturnPagination } from '@core/types'
import { Endereco, Parceiro } from './parceiros.types'

@Injectable({
  providedIn: 'root',
})
export class ParceirosService {
  private readonly baseUrlParceiro = `${environment.baseUrl}/cadastro/v2/parceiro`
  private readonly baseUrlEndereco = `${environment.baseUrl}/cadastro/v2/endereco`

  constructor(private http: HttpClient) {}

  getAllPaged(pagination: Pagination): Observable<ReturnPagination> {
    let params = new HttpParams()
    if (pagination.sort) params = params.set('_sort', pagination.sort)
    if (pagination.order) params = params.set('_order', pagination.order)
    if (pagination.page >= 0) params = params.set('_page', (pagination.page + 1).toString())
    if (pagination.limit) params = params.set('_limit', pagination.limit.toString())

    if (pagination.arraySearch && pagination.arraySearch.length > 0) {
      pagination.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    if (pagination.arraySearchAdvanced && pagination.arraySearchAdvanced.length > 0) {
      pagination.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    return this.http.get<ReturnPagination>(`${this.baseUrlParceiro}/page`, { params })
  }

  getAll(): Observable<Parceiro[]> {
    return this.http.get<Parceiro[]>(`${this.baseUrlParceiro}/all`)
  }

  getById(id: string | number): Observable<Parceiro> {
    return this.http.get<Parceiro>(`${this.baseUrlParceiro}/${id}`)
  }

  create(resource: Parceiro): Observable<Parceiro> {
    return this.http.post<Parceiro>(`${this.baseUrlParceiro}`, resource)
  }

  update(id: number | string, changes: Partial<Parceiro>): Observable<Parceiro> {
    return this.http.put<Parceiro>(`${this.baseUrlParceiro}/${id}`, changes)
  }

  upsert(parceiro: Parceiro): Observable<Parceiro> {
    return this.http.put<Parceiro>(`${environment.baseUrl}/cadastro/v2/parceiro/upsert`, parceiro)
  }

  remove(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrlParceiro}/${id}`)
  }

  removeMany(arrayIds: { id: number }[]) {
    return this.http.request('DELETE', this.baseUrlParceiro, { body: arrayIds, observe: 'body' })
  }

  searchParceiro(arrayClassification: Array<string>, search: string): Observable<Parceiro[]> {
    let params = new HttpParams()
    if (arrayClassification && arrayClassification.length > 0) {
      arrayClassification.forEach((value: string, index: number) => {
        if (index >= 0) params = params.set(value, 'true')
      })
    }

    return this.http.post<Parceiro[]>(
      `${environment.baseUrl}/cadastro/v2/parceiro/pesquisa`,
      { busca: search },
      { params }
    )
  }

  /* ENDEREÇO */
  getEnderecoPorId(id: number): Observable<Endereco> {
    return this.http.get<Endereco>(`${this.baseUrlEndereco}/${id}`)
  }

  getEnderecoPorParceiroId(id: number): Observable<Endereco[]> {
    return this.http.get<Endereco[]>(`${this.baseUrlEndereco}/parceiro/${id}`)
  }

  criarEndereco(endereco: Endereco): Observable<Endereco> {
    return this.http.post<Endereco>(`${this.baseUrlEndereco}`, endereco)
  }

  atualizarEndereco(endereco: Endereco): Observable<Endereco> {
    return this.http.put<Endereco>(`${this.baseUrlEndereco}/${endereco.id}`, endereco)
  }
}
