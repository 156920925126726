import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Store } from '@ngrx/store'
import { AppState, fromAuth } from '../root-store'

import { PermissionsService } from '@core/services'
import { Usuario } from '@modules/usuarios/usuarios.types'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private _store: Store<AppState>,
    private _router: Router,
    private _permissionsService: PermissionsService,
    private _toastrService: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._store.select(fromAuth.selectUser).pipe(
      map((user: Usuario | null) => {
        if (!!user && this._permissionsService.checkPermission(user, route.data['modulePermission'])) {
          return true
        }

        this._toastrService.info(
          'Este usuário não tem permissão suficiente para executar a ação!',
          'Permissão de acesso'
        )

        return this._router.parseUrl('')
      })
    )
  }
}
