import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment } from '@environments/environment'
import { Natureza } from '@core/types'

@Injectable({
  providedIn: 'root',
})
export class NatureService {
  readonly baseUrl: string = `${environment.baseUrl}/cadastro/v2/natureza`

  constructor(private http: HttpClient) {}

  searchNature(search: string): Observable<Natureza[]> {
    return this.http.post<Natureza[]>(`${this.baseUrl}/pesquisa`, { busca: search })
  }
}
