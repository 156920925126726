import { NavigationItem } from '@core/types'

export const defaultNavigation: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Geral',
    children: [
      {
        type: 'link',
        label: 'Painel',
        route: '/',
        icon: 'bar_chart',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Relatórios',
        route: '/relatorios',
        icon: 'query_stats',
      },
      {
        type: 'link',
        label: 'Parceiros',
        route: '/parceiros',
        icon: 'people',
      },
      {
        type: 'link',
        label: 'Veículos',
        route: '/veiculos',
        icon: 'commute',
      },
      {
        type: 'link',
        label: 'CTe',
        icon: 'local_shipping',
        route: '/ctes',
      },
      {
        type: 'link',
        label: 'MDFe',
        icon: 'menu_books',
        route: '/mdfes',
      },
    ],
  },
]
