import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'

import { MatDialog } from '@angular/material/dialog'

import { Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, exhaustMap, map, tap } from 'rxjs/operators'

import { ToastrService } from 'ngx-toastr'

import { AuthService } from '@core/services'

import { AppState } from '../app.state'
import {
  login,
  loginFailure,
  loginSuccess,
  changeCompany,
  changeCompanyFailure,
  changeCompanySuccess,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenFailure,
  logout,
} from './auth.actions'

@Injectable()
export class AuthEffects {
  /* Além de fazer o login, faz o usuario escolher em qual cnpj conectar,
  ou seja, quando confirmado troca os tokens. */
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(action =>
        this.authService.login(action.credentials).pipe(
          map(data => loginSuccess({ loginAuth: data })),
          catchError((error: HttpErrorResponse) => of(loginFailure({ error: error.error.message })))
        )
      )
    )
  )

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap(data => {
          this.authService.storeTokens(data.loginAuth.token, data.loginAuth.tokenRefresh)
          this.toastr.success('Conexão realizada com sucesso!', 'Credenciais')
          this.router.navigate(['/'])
        })
      ),
    { dispatch: false }
  )

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailure),
        tap(failure => {
          this.toastr.error(failure.error, 'Credenciais', {
            timeOut: 5000,
          })
        })
      ),
    { dispatch: false }
  )

  changeCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeCompany),
      exhaustMap(action =>
        this.authService.changeCompany(action.id).pipe(
          map(data => {
            this.authService.storeTokens(data.token, data.tokenRefresh)
            return changeCompanySuccess({ loginAuth: data })
          }),
          catchError(error => of(changeCompanyFailure({ error })))
        )
      )
    )
  )

  changeCompanySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeCompanySuccess),
        tap(data => {
          this.toastr.success('Empresa trocada com sucesso!', 'Trocar de empresa')
          this.dialog.closeAll()
          this.router.navigate(['/'])
        })
      ),
    { dispatch: false }
  )

  changeCompanyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeCompanyFailure),
        tap(data => {
          this.toastr.error('Não foi possível trocar de empresa, tente novamente!', 'Trocar de empresa', {
            timeOut: 5000,
          })
        })
      ),
    { dispatch: false }
  )

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshToken),
      exhaustMap(action =>
        this.authService.refreshToken().pipe(
          map(data => refreshTokenSuccess({ loginAuth: data })),
          catchError(error => of(refreshTokenFailure({ error })))
        )
      )
    )
  )

  refreshTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenSuccess),
        tap(data => {
          this.authService.storeTokens(data.loginAuth.token, data.loginAuth.tokenRefresh)
        })
      ),
    { dispatch: false }
  )

  refreshTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenFailure),
        tap(() => {
          this.toastr.error('Suas credenciais expiraram!', 'Credenciais', { timeOut: 5000 })
          this.store.dispatch(logout())
        })
      ),
    { dispatch: false }
  )

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.dialog.closeAll()
          this.authService.removeTokens()
          this.router.navigate(['/login'], { relativeTo: this.route })
        })
      ),
    { dispatch: false }
  )

  setTitle$ = createEffect(
    () =>
      this.actions$.pipe(ofType(loginSuccess, refreshTokenSuccess, changeCompanySuccess)).pipe(
        tap(({ loginAuth }) => {
          this.title.setTitle(
            ` ${loginAuth.usuario.empresaConectada.cnpj} - ${loginAuth.usuario.empresaConectada.nome} (${loginAuth.usuario.empresaConectada.uf})`
          )
        })
      ),
    { dispatch: false }
  )

  removeTitle$ = createEffect(
    () =>
      this.actions$.pipe(ofType(logout)).pipe(
        tap(() => {
          this.title.setTitle(' Syscontec ')
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private title: Title
  ) {}
}
