import { RouterReducerState } from '@ngrx/router-store'
import { createFeatureSelector } from '@ngrx/store'
import { AppState } from './app.state'
import { AuthState } from './auth'
import { RouterStateUrl } from './router'
import { SettingsState } from './settings/settings.state'

/* Seletores do Estado da Aplicaçaõ*/
export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router')

export const selectAuthState = createFeatureSelector<AuthState>('auth')

export const selectSettingsState = createFeatureSelector<SettingsState>('settings')
