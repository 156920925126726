import { createSelector } from '@ngrx/store'
import { Style } from '@core/enums'

import { SettingsState } from './settings.state'
import { selectSettingsState } from '../app.selectors'

export const selectSettings = createSelector(selectSettingsState, (state: SettingsState) => state)

export const selectTheme = createSelector(selectSettings, settings => settings.theme.toLowerCase())

export const checkSelectedTheme = (theme: Style) => createSelector(selectSettings, settings => settings.theme === theme)
