import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { environment } from '@environments/environment'

import { Pagination, ReturnPagination } from '@core/types'
import { ConjuntoVeiculo, Veiculo } from '../veiculos.types'

@Injectable({
  providedIn: 'root',
})
export class VeiculosService {
  private readonly baseUrl = `${environment.baseUrl}/cadastro/v2/veiculo`

  constructor(private http: HttpClient) {}

  getAllPaged(pagination: Pagination): Observable<ReturnPagination> {
    let params = new HttpParams()
    if (pagination.sort) params = params.set('_sort', pagination.sort)
    if (pagination.order) params = params.set('_order', pagination.order)
    if (pagination.page >= 0) params = params.set('_page', (pagination.page + 1).toString())
    if (pagination.limit) params = params.set('_limit', pagination.limit.toString())

    if (pagination.arraySearch && pagination.arraySearch.length > 0) {
      pagination.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    if (pagination.arraySearchAdvanced && pagination.arraySearchAdvanced.length > 0) {
      pagination.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    return this.http.get<ReturnPagination>(`${this.baseUrl}/page`, { params })
  }

  getAll(): Observable<Veiculo[]> {
    return this.http.get<Veiculo[]>(`${this.baseUrl}/all`)
  }

  getById(id: string | number): Observable<Veiculo> {
    return this.http.get<Veiculo>(`${this.baseUrl}/${id}`)
  }

  create(resource: Veiculo): Observable<Veiculo> {
    return this.http.post<Veiculo>(`${this.baseUrl}`, resource)
  }

  update(id: number | string, changes: Partial<Veiculo>): Observable<Veiculo> {
    return this.http.put<Veiculo>(`${this.baseUrl}/${id}`, changes)
  }

  upsert(parceiro: Veiculo): Observable<Veiculo> {
    return this.http.put<Veiculo>(`${environment.baseUrl}/cadastro/v2/parceiro/upsert`, parceiro)
  }

  remove(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`)
  }

  removeMany(arrayIds: { id: number }[]) {
    return this.http.request('DELETE', this.baseUrl, { body: arrayIds, observe: 'body' })
  }

  searchVeiculo(configPlaca: string, search: string): Observable<ConjuntoVeiculo[]> {
    return this.http.get<ConjuntoVeiculo[]>(
      `${environment.baseUrl}/cadastro/v2/veiculo/pesquisa/nova/${configPlaca}/${search}`
    )
  }
}
