import * as moment from 'moment'

export const isBoolean = (value: string): boolean => {
  return /^\s*(true|false)\s*$/i.test(value)
}

export const isNumericType = (value: string): boolean => {
  return /^\d+$/.test(value)
}

export const isDateType = (value: string): boolean => {
  return moment(value, 'YYYY-MM-DD', true).isValid()
}
