import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { Pagination, ReturnPagination, TransmissionResponse, ColumnsPage, City } from '@core/types'
import { Mdfe } from '@modules/mdfes/mdfes.types'

/* LOAD os registros */
export const loadAll = createAction('[MDFES] Load', props<{ pagination: Pagination }>())

export const loadAllSuccess = createAction('[MDFES] Load Success', props<{ returnPagination: ReturnPagination }>())

export const loadAllFailure = createAction('[MDFES] Load Failure', props<{ error: string }>())

/* LOAD ONE registro */
export const loadOne = createAction('[MDFES] Load One ', props<{ mdfeId: number }>())

export const loadOneSuccess = createAction('[MDFES] Load One Success', props<{ mdfe: Mdfe }>())

export const loadOneFailure = createAction('[MDFES] Load One Failure', props<{ error: string }>())

/* Change columns */
export const updateColumns = createAction('[MDFES] Update columns', props<{ columns: ColumnsPage[] }>())

/* CREATE um registro */
export const add = createAction('[MDFES] Create', props<{ mdfe: Mdfe }>())

export const addSuccess = createAction('[MDFES] Create Success', props<{ mdfe: Mdfe }>())

export const addFailure = createAction('[MDFES] Create Failure', props<{ error: string }>())

/* CREATE diversos registros */
export const addMany = createAction('[MDFES] Create Many', props<{ mdfes: Mdfe[] }>())

export const addManySuccess = createAction('[MDFES] Create Many Success', props<{ mdfes: Mdfe[] }>())

export const addManyFailure = createAction('[MDFES] Create Many Failure', props<{ error: string }>())

/* REMOVE vários registros */
export const removeMany = createAction('[MDFES] Remove Many', props<{ ids: { id: number }[] }>())

export const removeManySuccess = createAction('[MDFES] Remove Many Success', props<{ ids: number[] }>())

export const removeManyFailure = createAction('[MDFES] Remove Many Failure', props<{ error: string }>())

/* UPDATE um registro */
export const update = createAction('[MDFES] Update', props<{ update: Update<Mdfe> }>())

export const updateSuccess = createAction('[MDFES] Update Success', props<{ update: Update<Mdfe> }>())

export const updateFailure = createAction('[MDFES] Update Failure', props<{ error: string }>())

/* CREATE um mdfe e já manda Autorizar */
export const upsertEmitir = createAction('[MDFES] Upsert / Emitir', props<{ mdfe: Mdfe }>())

export const upsertEmitirSuccess = createAction(
  '[MDFES] Upsert / Emitir Success',
  props<{ mdfe: Mdfe; retornoMdfe: TransmissionResponse }>()
)

export const upsertEmitirFailure = createAction('[MDFES] Upsert / Emitir Failure', props<{ error: string }>())

/* AUTORIZAR um conhecimento */
export const autorizar = createAction('[MDFES] Autorizar', props<{ ids: [{ id: number }] }>())

export const autorizarSuccess = createAction('[MDFES] AutorizarSuccess', props<{ retornoMdfe: TransmissionResponse }>())

export const autorizarFailure = createAction('[MDFES] Autorizar Failure', props<{ error: string }>())

/* CANCELAR um conhecimento */

export const cancelar = createAction(
  '[MDFES] Cancelar',
  props<{ objCancelamento: { mdfId: number; justificativa: string } }>()
)

export const cancelarSuccess = createAction('[MDFES] Cancelar Success', props<{ retornoMdfe: TransmissionResponse }>())

export const cancelarFailure = createAction('[MDFES] Cancelar  Failure', props<{ error: string }>())

/* ENCERRAR um conhecimento */

export const encerrar = createAction(
  '[MDFES] Encerrar',
  props<{
    objEncerramento: {
      cidadeEncerramento: City
      mdfId: number
      chave: string | null
      protocolo: string | null
    }
  }>()
)

export const encerrarSuccess = createAction('[MDFES] Encerrar Success', props<{ retornoMdfe: TransmissionResponse }>())

export const encerrarFailure = createAction('[MDFES] Encerrar  Failure', props<{ error: string }>())

/* Upsert mdfe em contingencia */
export const upsertContingencia = createAction('[MDFES] Upsert contingencia', props<{ mdfe: Mdfe }>())

export const upsertContingenciaSuccess = createAction(
  '[MDFES] Upsert contingencia Success',
  props<{ mdfe: Mdfe; retornoMdfe: TransmissionResponse }>()
)

export const upsertContingenciaFailure = createAction('[MDFES] Upsert contingencia Failure', props<{ error: string }>())
