import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Pagination, ReturnPagination, TransmissionResponse } from '@core/types'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'
import { AverbacaoCte, Cte, FormulaCalculoCte } from '../types/ctes.types'

@Injectable({
  providedIn: 'root',
})
export class CtesService {
  private readonly baseUrl = `${environment.baseUrl}/emissao/v1/cte`

  constructor(private http: HttpClient) {}

  getAllPaged(
    pagination: Pagination,
    retornoAverbacao: boolean = false,
    resumido: boolean = false
  ): Observable<ReturnPagination> {
    let params = new HttpParams()
    if (pagination.sort) params = params.set('_sort', pagination.sort)
    if (pagination.order) params = params.set('_order', pagination.order)
    if (pagination.page >= 0) params = params.set('_page', (pagination.page + 1).toString())
    if (pagination.limit) params = params.set('_limit', pagination.limit.toString())
    if (retornoAverbacao) params = params.set('_averbacao', retornoAverbacao)
    if (resumido) params = params.set('_resumido', resumido)

    if (pagination.arraySearch && pagination.arraySearch.length > 0) {
      pagination.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    if (pagination.arraySearchAdvanced && pagination.arraySearchAdvanced.length > 0) {
      pagination.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    return this.http.get<ReturnPagination>(`${this.baseUrl}/page`, { params })
  }

  getAll(): Observable<Cte[]> {
    return this.http.get<Cte[]>(`${this.baseUrl}/all`)
  }

  getById(id: string | number): Observable<Cte> {
    return this.http.get<Cte>(`${this.baseUrl}/${id}`)
  }

  create(resource: Cte): Observable<Cte> {
    return this.http.post<Cte>(`${this.baseUrl}`, resource)
  }

  createMany(ctes: Array<Cte>): Observable<Cte[]> {
    return this.http.post<Cte[]>(`${this.baseUrl}/importar`, ctes)
  }

  update(id: number | string, changes: Partial<Cte>): Observable<Cte> {
    return this.http.put<Cte>(`${this.baseUrl}/${id}`, changes)
  }

  upsertEmitir(cte: Cte): Observable<{ objeto: Cte; respostas: TransmissionResponse }> {
    return this.http.put<{ objeto: Cte; respostas: TransmissionResponse }>(`${this.baseUrl}/upsert/emitir`, cte)
  }

  removeMany(arrayIds: { id: number }[]) {
    return this.http.request('DELETE', this.baseUrl, { body: arrayIds, observe: 'body' })
  }

  cancelar(objCancelamento: { cteId: number; justificativa: string }): Observable<TransmissionResponse> {
    return this.http.post<TransmissionResponse>(`${this.baseUrl}/cancelar`, objCancelamento)
  }

  autorizar(arrayIds: [{ id: number }]): Observable<TransmissionResponse> {
    return this.http.post<TransmissionResponse>(`${this.baseUrl}/emitir`, arrayIds)
  }

  imprimir(id: number) {
    return this.http.get(`${this.baseUrl}/imprimir/${id}`, { responseType: 'blob' as 'json' })
  }

  downloadXml(object: {
    arrayIds: [{ id: number }]
    selectAll: boolean
    arraySearch: Array<{ key: string; value: string }>
    arraySearchAdvanced: Array<{ key: string; value: string }>
  }) {
    let params = new HttpParams()

    if (object.selectAll) {
      params = params.set('_todos', `${object.selectAll}`)
      if (object.arraySearch && object.arraySearch.length > 0) {
        object.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }

      if (object.arraySearchAdvanced && object.arraySearchAdvanced.length > 0) {
        object.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }
    }
    return this.http.post(`${this.baseUrl}/exportar/xml`, object.arrayIds, {
      params,
      responseType: 'blob' as 'json',
    })
  }

  downloadPdf(object: {
    arrayIds: [{ id: number }]
    selectAll: boolean
    arraySearch: Array<{ key: string; value: string }>
    arraySearchAdvanced: Array<{ key: string; value: string }>
  }) {
    let params = new HttpParams()

    if (object.selectAll) {
      params = params.set('_todos', `${object.selectAll}`)
      if (object.arraySearch && object.arraySearch.length > 0) {
        object.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }

      if (object.arraySearchAdvanced && object.arraySearchAdvanced.length > 0) {
        object.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }
    }

    return this.http.post(`${this.baseUrl}/exportar/pdf`, object.arrayIds, {
      params,
      responseType: 'blob' as 'json',
    })
  }

  downloadDocumentos(object: {
    arrayIds: [{ id: number }]
    selectAll: boolean
    arraySearch: Array<{ key: string; value: string }>
    arraySearchAdvanced: Array<{ key: string; value: string }>
  }) {
    let params = new HttpParams()

    if (object.selectAll) {
      params = params.set('_todos', `${object.selectAll}`)
      if (object.arraySearch && object.arraySearch.length > 0) {
        object.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }

      if (object.arraySearchAdvanced && object.arraySearchAdvanced.length > 0) {
        object.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }
    }
    return this.http.post(`${this.baseUrl}/exportar/documentos`, object.arrayIds, {
      params,
      responseType: 'blob' as 'json',
    })
  }

  gerarCteNormal(id: number): Observable<Cte> {
    return this.http.post<Cte>(`${this.baseUrl}/copiarColar/${id}`, {})
  }

  gerarCteAnulacao(id: number): Observable<Cte> {
    return this.http.post<Cte>(`${this.baseUrl}/gerarAnulacao/${id}`, {})
  }

  gerarCteComplementar(id: number): Observable<Cte> {
    return this.http.post<Cte>(`${this.baseUrl}/gerarComplementar/${id}`, {})
  }

  getFormulas(): Observable<FormulaCalculoCte[]> {
    return this.http.get<FormulaCalculoCte[]>(`${this.baseUrl}/formulas/all`)
  }

  getHistoricoRetornos(id: number): Observable<TransmissionResponse> {
    return this.http.get<TransmissionResponse>(`${this.baseUrl}/retornos/${id}`)
  }

  getHistoricoRetornosAverbacao(id: number): Observable<TransmissionResponse> {
    return this.http.get<TransmissionResponse>(`${this.baseUrl}/averbacao/${id}`)
  }

  reenviarAverbacao(id: number): Observable<AverbacaoCte> {
    return this.http.post<AverbacaoCte>(`${this.baseUrl}/averbacao/reenviar/${id}`, {})
  }
}
