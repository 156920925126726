import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Store } from '@ngrx/store'
import { AppState, fromAuth } from '@core/root-store'

import { ModulePermission } from '@core/types'
import { Usuario } from '@modules/usuarios/usuarios.types'

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private store: Store<AppState>) {}

  checkPermission(user: Usuario, modulePermission: Array<ModulePermission>, operator: boolean = true): boolean {
    const permissoes: Array<boolean> = []

    if (!!modulePermission && modulePermission.length > 0) {
      modulePermission?.forEach((value: ModulePermission, i: number) => {
        if (value?.module && value?.permission) permissoes.push(user?.permissoes?.[value?.module]?.[value?.permission])
      })
    }

    if (!!permissoes && permissoes.length === 1) {
      return permissoes[0]
    }

    if (!!permissoes && permissoes.length > 1) {
      if (operator) return permissoes.reduce((ant: boolean, atual: boolean) => ant && atual)

      return permissoes.reduce((ant: boolean, atual: boolean) => ant || atual)
    }

    return false
  }

  checkPermissionWithOutUser(modulePermission: Array<ModulePermission>, operator: boolean = true): Observable<boolean> {
    return this.store.select(fromAuth.selectUser).pipe(
      map((user: Usuario) => {
        if (!!user && this.checkPermission(user, modulePermission, operator)) {
          return true
        }

        return false
      })
    )
  }
}
