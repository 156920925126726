import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { BreakpointObserver } from '@angular/cdk/layout'
import { map } from 'rxjs/operators'
import { LocalStorageService } from './local-storage.service'
import { isBoolean } from '@shared/utils/types'

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public isDesktop$ = this.breakpointObserver
    .observe('screen and (min-width: 1280px)')
    .pipe(map(state => state.matches))
  public ltLg$ = this.breakpointObserver.observe('screen and (max-width: 1279px)').pipe(map(state => state.matches))
  public ltMd$ = this.breakpointObserver.observe('screen and (max-width: 959px)').pipe(map(state => state.matches))
  public gtSm$ = this.breakpointObserver.observe('screen and (min-width: 960px)').pipe(map(state => state.matches))
  public gtXs$ = this.breakpointObserver.observe('screen and (min-width: 600px)').pipe(map(state => state.matches))
  public isMobile$ = this.breakpointObserver.observe('screen and (max-width: 599px)').pipe(map(state => state.matches))

  private _sidenavOpenSubject = new BehaviorSubject<boolean>(false)
  private _sidenavOverModeSubject = new BehaviorSubject<boolean>(false)

  constructor(private breakpointObserver: BreakpointObserver, private localStorageService: LocalStorageService) {
    const sidenavMode = this.localStorageService.getItemLocalStorage('sidenav_mode')
    if (sidenavMode !== null && isBoolean(sidenavMode)) {
      this._sidenavOverModeSubject.next(JSON.parse(sidenavMode.toLowerCase()))
    }
  }

  get sidenavOpen$(): Observable<boolean> {
    return this._sidenavOpenSubject.asObservable()
  }

  get sidenavOverMode$(): Observable<boolean> {
    return this._sidenavOverModeSubject.asObservable()
  }

  openSidenav() {
    this._sidenavOpenSubject.next(true)
  }

  closeSidenav() {
    this._sidenavOpenSubject.next(false)
  }

  sidenavModeSide() {
    this._sidenavOverModeSubject.next(false)
    this.localStorageService.setItemLocalStorage('sidenav_mode', false)
  }

  sidenavModeOver() {
    this._sidenavOverModeSubject.next(true)
    this.localStorageService.setItemLocalStorage('sidenav_mode', true)
  }
}
