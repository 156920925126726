import { LocalStorageService } from '@core/services'
import { mergeDeep } from '@shared/utils/merge-deep'
import { ActionReducer, INIT, UPDATE } from '@ngrx/store'
import { AppState } from '../app.state'

export function initStateFromLocalStorage(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state, action) {
    const newState = reducer(state, action)
    if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
      return mergeDeep({ ...newState }, LocalStorageService.loadInitialState())
    }
    return newState
  }
}
