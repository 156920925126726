import { ActionReducerMap, MetaReducer } from '@ngrx/store'
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer'
import { logoutClearState } from './meta-reducers/logout.reducer'

import { RouterStateUrl } from './router'
import { routerReducer, RouterReducerState } from '@ngrx/router-store'

import * as fromAuth from './auth'
import * as fromSettings from './settings'
import * as fromParceiros from './parceiros'
import * as fromVeiculos from './veiculos'
import * as fromCtes from './ctes'
import * as fromMdfes from './mdfes'

/* Reducer principal */
export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: fromAuth.reducer,
  settings: fromSettings.reducer,
  parceiros: fromParceiros.reducer,
  veiculos: fromVeiculos.reducer,
  ctes: fromCtes.reducer,
  mdfes: fromMdfes.reducer,
}

/* metaReducers são ações que devam ser executadas antes do routerReducer */
/* interessante utilizar para carregar ddados iniciais como informações no local storage  */
export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage, logoutClearState]

/* Estado Aplicação */
export interface AppState {
  router: RouterReducerState<RouterStateUrl>
  auth: fromAuth.AuthState
  settings: fromSettings.SettingsState
  parceiros: fromParceiros.ParceirosState
  veiculos: fromVeiculos.VeiculosState
  ctes: fromCtes.CtesState
  mdfes: fromMdfes.MdfesState
}
