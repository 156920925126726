import { createReducer, on } from '@ngrx/store'
import {
  login,
  loginSuccess,
  loginFailure,
  logout,
  changeCompany,
  changeCompanySuccess,
  changeCompanyFailure,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenFailure,
  updateUsuario,
} from './auth.actions'
import { AuthState } from './auth.state'

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
}

export const reducer = createReducer(
  initialState,
  on(login, state => ({ ...state, loading: true })),
  on(loginSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    user: action.loginAuth.usuario,
    error: null,
    loading: false,
  })),
  on(loginFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    error: action.error,
    loading: false,
  })),
  on(changeCompany, (state, action) => ({ ...state, loading: true })),
  on(changeCompanySuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    user: action.loginAuth.usuario,
    error: null,
    loading: false,
  })),
  on(changeCompanyFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(refreshToken, (state, action) => ({ ...state })),
  on(refreshTokenSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    user: action.loginAuth.usuario,
    error: null,
    loading: false,
  })),
  on(refreshTokenFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    error: action.error,
    loading: false,
  })),
  on(updateUsuario, (state, { usuario }) => {
    let user = state.user
    const novasPermissoes = {
      agenciaBancaria: usuario.agenciaBancaria,
      cfop: usuario.cfop,
      configuracoes: usuario.configuracoes,
      cte: usuario.cte,
      email: usuario.email,
      empresas: usuario.empresas,
      grupoUsuario: usuario.grupoUsuario,
      inutilizacao: usuario.inutilizacao,
      mdfe: usuario.mdfe,
      paineis: usuario.paineis,
      parceiros: usuario.parceiros,
      relatorios: usuario.relatorios,
      seguro: usuario.seguro,
      certificado: usuario.certificado,
      serie: usuario.serie,
      unidadesMedida: usuario.unidadesMedida,
      usuarios: usuario.usuarios,
      veiculos: usuario.veiculos,

      empresasAcesso: usuario.empresasAcesso,
    }

    if (state.user?.id === usuario.id) {
      user = Object.assign({}, state.user, {
        permissoes: Object.assign({}, state.user?.permissoes, novasPermissoes),
      })
    }

    return { ...state, user }
  }),
  on(logout, () => initialState)
)
