import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'

import { Pagination, ReturnPagination, ColumnsPage } from '@core/types'
import { Parceiro } from '@modules/parceiros/parceiros.types'

/* Carrega os registros */
export const loadAll = createAction('[PARCEIRO] Load', props<{ pagination: Pagination }>())

export const loadAllSuccess = createAction('[PARCEIRO] Load Success', props<{ returnPagination: ReturnPagination }>())

export const loadAllFailure = createAction('[PARCEIRO] Load Failure', props<{ error: string }>())

/* Carrega um registro */
export const loadOne = createAction('[PARCEIRO] Load One', props<{ parceiroId: number }>())

export const loadOneSuccess = createAction('[PARCEIRO] Load One Success', props<{ parceiro: Parceiro }>())

export const loadOneFailure = createAction('[PARCEIRO] Load One Failure', props<{ error: string }>())

/* Adiciona um registro */
export const add = createAction('[PARCEIRO] Create', props<{ parceiro: Parceiro }>())

export const addSuccess = createAction('[PARCEIRO] Create Success', props<{ parceiro: Parceiro }>())

export const addFailure = createAction('[PARCEIRO] Create Failure', props<{ error: string }>())

/* Remove um registro */
export const remove = createAction('[PARCEIRO] Remove', props<{ id: number }>())

export const removeSuccess = createAction('[PARCEIRO] Remove Success', props<{ id: number }>())

export const removeFailure = createAction('[PARCEIRO] Remove Failure', props<{ error: string }>())

/* Remove vários registros */
export const removeMany = createAction('[PARCEIRO] Remove Many', props<{ ids: { id: number }[] }>())

export const removeManySuccess = createAction('[PARCEIRO] Remove Many Success', props<{ ids: number[] }>())

export const removeManyFailure = createAction('[PARCEIRO] Remove Many Failure', props<{ error: string }>())

/* Atualiza um registro */
export const update = createAction('[PARCEIRO] Update', props<{ update: Update<Parceiro> }>())

export const updateSuccess = createAction('[PARCEIRO] Update Success', props<{ update: Update<Parceiro> }>())

export const updateFailure = createAction('[PARCEIRO] Update Failure', props<{ error: string }>())

/* Atualizar columns */
export const updateColumns = createAction('[PARCEIRO] Update columns', props<{ columns: ColumnsPage[] }>())
