import { EntityState } from '@ngrx/entity'
import { Pagination } from '@core/types/pagination'
import { Parceiro } from '@modules/parceiros/parceiros.types'

export interface ParceirosState extends EntityState<Parceiro> {
  totalItens: number
  loadingList: boolean
  loadingForm: boolean
  pagination: Pagination
  error: string | undefined
}
