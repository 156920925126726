import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { NavigationItem, NavigationDropdown, NavigationLink, NavigationSubheading } from '@core/types/navigation'
import { defaultNavigation } from '@core/constants/navigation'

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public items: Array<NavigationItem> = defaultNavigation

  private _openChangeSubject = new Subject<NavigationDropdown>()

  get openChange$(): Observable<NavigationDropdown> {
    return this._openChangeSubject.asObservable()
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item)
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link'
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown'
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading'
  }
}
