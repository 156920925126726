import { Injectable } from '@angular/core'
import { Usuario } from '@modules/usuarios/usuarios.types'

@Injectable({
  providedIn: 'root',
})
export class CurrentModulesPermissionsService {
  constructor() {}

  checkPermission(user: Usuario, currentModuleType: number): boolean {
    const module = user.empresaConectada.modulosAtuais.find((module: any) => module.tipo === currentModuleType)

    if (module) return module.ativo

    return false
  }
}
