export enum Permission {
  Visualizar = 'visualizar',
  Incluir = 'incluir',
  Editar = 'editar',
  Excluir = 'excluir',
  Autorizar = 'autorizar',
  Cancelar = 'cancelar',
  Encerrar = 'encerrar',
  CartaCorrecao = 'cartaCorrecao',
  MotoristaAdicional = 'motoristaAdicional',
}
