import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment } from '@environments/environment'

import { City, Pagination, ReturnPagination, TransmissionResponse } from '@core/types'
import { Mdfe, MotoristaAdicional, naoEncerradoMdfe } from '../mdfes.types'

@Injectable({
  providedIn: 'root',
})
export class MdfesService {
  private readonly baseUrl = `${environment.baseUrl}/emissao/v1/mdf`

  constructor(private http: HttpClient) {}

  getAllPaged(pagination: Pagination): Observable<ReturnPagination> {
    let params = new HttpParams()
    if (pagination.sort) params = params.set('_sort', pagination.sort)
    if (pagination.order) params = params.set('_order', pagination.order)
    if (pagination.page >= 0) params = params.set('_page', (pagination.page + 1).toString())
    if (pagination.limit) params = params.set('_limit', pagination.limit.toString())

    if (pagination.arraySearch && pagination.arraySearch.length > 0) {
      pagination.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    if (pagination.arraySearchAdvanced && pagination.arraySearchAdvanced.length > 0) {
      pagination.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
        if (index >= 0) params = params.set(value.key, value.value)
      })
    }

    return this.http.get<ReturnPagination>(`${this.baseUrl}/page`, { params })
  }

  getAll(): Observable<Mdfe[]> {
    return this.http.get<Mdfe[]>(`${this.baseUrl}/all`)
  }

  getById(id: string | number): Observable<Mdfe> {
    return this.http.get<Mdfe>(`${this.baseUrl}/${id}`)
  }

  create(resource: Mdfe): Observable<Mdfe> {
    return this.http.post<Mdfe>(`${this.baseUrl}`, resource)
  }

  createMany(ctes: Array<Mdfe>): Observable<Mdfe[]> {
    return this.http.post<Mdfe[]>(`${this.baseUrl}/importar`, ctes)
  }

  update(id: number | string, changes: Partial<Mdfe>): Observable<Mdfe> {
    return this.http.put<Mdfe>(`${this.baseUrl}/${id}`, changes)
  }

  removeMany(arrayIds: { id: number }[]) {
    return this.http.request('DELETE', this.baseUrl, { body: arrayIds, observe: 'body' })
  }

  getHistoricoRetornos(id: number): Observable<TransmissionResponse> {
    return this.http.get<TransmissionResponse>(`${this.baseUrl}/retornos/${id}`)
  }

  cancelar(objCancelamento: { mdfId: number; justificativa: string }): Observable<TransmissionResponse> {
    return this.http.post<TransmissionResponse>(`${this.baseUrl}/cancelar`, objCancelamento)
  }

  autorizar(arrayIds: [{ id: number }]): Observable<TransmissionResponse> {
    return this.http.post<TransmissionResponse>(`${this.baseUrl}/emitir`, arrayIds)
  }

  encerrar(objEncerramento: {
    cidadeEncerramento: City
    mdfId: number
    chave: string | null
    protocolo: string | null
  }): Observable<TransmissionResponse> {
    return this.http.post<TransmissionResponse>(`${this.baseUrl}/encerrar`, objEncerramento)
  }

  getNaoEncerrados(): Observable<naoEncerradoMdfe[]> {
    return this.http.get<naoEncerradoMdfe[]>(`${this.baseUrl}/naoEncerrados`)
  }

  upsertMotoristaAdicional(motorista: MotoristaAdicional): Observable<MotoristaAdicional> {
    return this.http.put<MotoristaAdicional>(`${this.baseUrl}/motorista`, motorista)
  }

  getMotoristasAdicionais(mdfeId: number): Observable<MotoristaAdicional[]> {
    return this.http.get<MotoristaAdicional[]>(`${this.baseUrl}/motorista/${mdfeId}`)
  }

  imprimir(id: number) {
    return this.http.get(`${this.baseUrl}/imprimir/${id}`, { responseType: 'blob' as 'json' })
  }

  downloadXml(object: {
    arrayIds: [{ id: number }]
    selectAll: boolean
    arraySearch: Array<{ key: string; value: string }>
    arraySearchAdvanced: Array<{ key: string; value: string }>
  }) {
    let params = new HttpParams()

    if (object.selectAll) {
      params = params.set('_todos', `${object.selectAll}`)
      if (object.arraySearch && object.arraySearch.length > 0) {
        object.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }

      if (object.arraySearchAdvanced && object.arraySearchAdvanced.length > 0) {
        object.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }
    }
    return this.http.post(`${this.baseUrl}/exportar/xml`, object.arrayIds, {
      params,
      responseType: 'blob' as 'json',
    })
  }

  downloadPdf(object: {
    arrayIds: [{ id: number }]
    selectAll: boolean
    arraySearch: Array<{ key: string; value: string }>
    arraySearchAdvanced: Array<{ key: string; value: string }>
  }) {
    let params = new HttpParams()

    if (object.selectAll) {
      params = params.set('_todos', `${object.selectAll}`)
      if (object.arraySearch && object.arraySearch.length > 0) {
        object.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }

      if (object.arraySearchAdvanced && object.arraySearchAdvanced.length > 0) {
        object.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }
    }
    return this.http.post(`${this.baseUrl}/exportar/pdf`, object.arrayIds, {
      params,
      responseType: 'blob' as 'json',
    })
  }

  downloadDocumentos(object: {
    arrayIds: [{ id: number }]
    selectAll: boolean
    arraySearch: Array<{ key: string; value: string }>
    arraySearchAdvanced: Array<{ key: string; value: string }>
  }) {
    let params = new HttpParams()

    if (object.selectAll) {
      params = params.set('_todos', `${object.selectAll}`)
      if (object.arraySearch && object.arraySearch.length > 0) {
        object.arraySearch.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }

      if (object.arraySearchAdvanced && object.arraySearchAdvanced.length > 0) {
        object.arraySearchAdvanced.forEach((value: { key: string; value: string }, index: number) => {
          if (index >= 0) params = params.set(value.key, value.value)
        })
      }
    }
    return this.http.post(`${this.baseUrl}/exportar/documentos`, object.arrayIds, {
      params,
      responseType: 'blob' as 'json',
    })
  }

  upsertEmitir(mdfe: Mdfe): Observable<{ objeto: Mdfe; respostas: TransmissionResponse }> {
    return this.http.put<{ objeto: Mdfe; respostas: TransmissionResponse }>(`${this.baseUrl}/upsert/emitir`, mdfe)
  }

  /* Requisições em contingencia */
  upsertContingencia(mdfe: Mdfe): Observable<{ objeto: Mdfe; respostas: TransmissionResponse }> {
    return this.http.put<{ objeto: Mdfe; respostas: TransmissionResponse }>(`${this.baseUrl}/contingencia/upsert`, mdfe)
  }

  imprimirContingencia(id: number) {
    return this.http.get(`${this.baseUrl}/contingencia/imprimir/${id}`, {
      responseType: 'blob' as 'json',
    })
  }
}
