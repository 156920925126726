import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router'

import { select, Store } from '@ngrx/store'

import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, filter, switchMap, take } from 'rxjs/operators'

import { ToastrService } from 'ngx-toastr'

import { AppState, fromAuth } from '@core/root-store'

import { AuthService } from '@core/services'
import { loginAuth } from '@core/types'

import { environment } from '@environments/environment'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* Faz a verificação se tem token e usuario */
    /* Nâo é necessário adicionar o token na requisiçãõ de login, por isso desta verificação */

    if (this.authService.getJwtToken() && this.store.pipe(select(fromAuth.selectUser))) {
      request = this.addToken(request, this.authService.getJwtToken())
    }

    if (!environment.production) {
      console.log(new Date(), request)
    }

    return next.handle(request).pipe(
      /* Operador que captura o erro */
      catchError((error: Error) => {
        /* Verificação para determinar se é um erro e qual seu status,
        cada status deverá executar uma função especifica  */
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleError401(request, next, error)
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          return this.handleError403(error)
        } else if (error instanceof HttpErrorResponse && error.status === 418) {
          return this.handleError418(error)
        } else {
          return throwError(error)
        }
      })
    )
  }

  /* Funçaõ que adicionar token a requisição */
  private addToken(req: HttpRequest<any>, token: string | null) {
    return req.clone({
      //eslint-disable-next-line
      setHeaders: { Authorization: `Bearer ${token}` },
    })
  }

  /* Funçaõ que lida com o erro 401, ou seja, Token não autorizado */
  private handleError401(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    /* Caso não tenha token de refresh no local Storage já faz logout */
    if (!this.authService.getRefreshToken()) {
      return this.logoutUser(error)
    }

    /* Faz o processo de refresh,
    em caso de sucesso atualiza as informaçaõ e retorna a requisição já atualizada */

    /* Qualquer erro executar função de logout */
    if (!this.isRefreshing) {
      this.isRefreshing = true
      this.refreshTokenSubject.next(null)
      return this.authService.refreshToken().pipe(
        switchMap((data: loginAuth) => {
          this.isRefreshing = false
          if (data) {
            this.refreshTokenSubject.next(data.token)
            this.store.dispatch(fromAuth.refreshTokenSuccess({ loginAuth: data }))
            return next.handle(this.addToken(request, data.token))
          }
          return this.logoutUser(error)
        }),
        catchError((error: HttpErrorResponse) => {
          this.isRefreshing = false
          return this.logoutUser(error)
        })
      )
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          this.isRefreshing = false
          return next.handle(this.addToken(request, token))
        }),
        catchError((error: HttpErrorResponse) => {
          this.isRefreshing = false
          return this.logoutUser(error)
        })
      )
    }
  }

  /* Função responsavel pelo erro 403 */
  /* 403 - Referente a erros do refresh token */
  /* Automaticamente faz logout */
  private handleError403(error: HttpErrorResponse) {
    return this.logoutUser(error)
  }

  private handleError418(error: HttpErrorResponse) {
    this.router.navigate(['/payments'])
    this.toastr.error(error.error.message)
    return throwError(() => error)
  }

  /* Funçaõ responsavel pelo logout do usuario */
  private logoutUser(error: HttpErrorResponse) {
    this.store.dispatch(fromAuth.logout())
    return throwError(() => error)
  }
}
