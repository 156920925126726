import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'

import {
  add,
  addFailure,
  addMany,
  addManyFailure,
  addManySuccess,
  addSuccess,
  loadAll,
  loadAllFailure,
  loadAllSuccess,
  loadOne,
  loadOneFailure,
  loadOneSuccess,
  removeMany,
  removeManyFailure,
  removeManySuccess,
  update,
  updateFailure,
  updateSuccess,
  autorizar,
  autorizarSuccess,
  autorizarFailure,
  upsertEmitir,
  upsertEmitirSuccess,
  upsertEmitirFailure,
  cancelar,
  cancelarSuccess,
  cancelarFailure,
  encerrar,
  encerrarSuccess,
  encerrarFailure,
  upsertContingencia,
  upsertContingenciaSuccess,
  upsertContingenciaFailure,
  updateColumns,
} from './mdfes.actions'

import { ColumnsPage } from '@core/types'
import { Mdfe } from '@modules/mdfes/mdfes.types'
import { MdfesState } from '.'

export const adapter: EntityAdapter<Mdfe> = createEntityAdapter<Mdfe>()

export const initialColumns: ColumnsPage[] = [
  {
    label: 'Série',
    name: 'serie',
    show: true,
  },
  {
    label: 'Número',
    name: 'numero',
    show: true,
  },
  {
    label: 'Percurso',
    name: 'percurso',
    show: true,
  },
  {
    label: 'Veículo 1',
    name: 'veiculo1',
    show: true,
  },
  {
    label: 'Veículo 2',
    name: 'veiculo2',
    show: false,
  },
  {
    label: 'Veículo 3',
    name: 'veiculo3',
    show: false,
  },
  {
    label: 'Veículo 4',
    name: 'veiculo4',
    show: false,
  },
  {
    label: 'Valor Total da Carga',
    name: 'valortotalcarga',
    show: true,
  },
  {
    label: 'Peso Bruto da Carga',
    name: 'pesobrutocarga',
    show: true,
  },
  {
    label: 'Status',
    name: 'statussefaz',
    show: true,
  },
]

export const initialState: MdfesState = adapter.getInitialState({
  totalItens: 0,
  loadingList: false,
  loadingForm: false,
  pagination: {
    sort: 'id',
    order: 'desc',
    page: 0,
    limit: 20,
    columns: initialColumns,
    arraySearch: [],
    arraySearchAdvanced: [],
  },
  error: undefined,
})

export const reducer = createReducer(
  initialState,
  on(loadAll, (state, action) => ({
    ...state,
    loadingList: true,
    pagination: { ...state.pagination, ...action.pagination },
    error: undefined,
  })),
  on(loadAllSuccess, (state, { returnPagination }) => {
    return adapter.setAll(returnPagination.itens, {
      ...state,
      totalItens: returnPagination.quantidadeRegistros,
      loadingList: false,
      error: undefined,
    })
  }),
  on(loadAllFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Carregamento de um registro */
  on(loadOne, (state, action) => ({
    ...state,
    error: undefined,
  })),
  on(loadOneSuccess, (state, { mdfe }) => {
    return adapter.setOne(mdfe, {
      ...state,
      error: undefined,
    })
  }),
  on(loadOneFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(updateColumns, (state, { columns }) => ({
    ...state,
    pagination: { ...state.pagination, ...{ columns } },
  })),

  /* Adicionando um registro */
  on(add, (state, action) => ({ ...state, loadingForm: true, error: undefined })),
  on(addSuccess, (state, { mdfe }) => {
    if (state.totalItens >= state.pagination.limit) {
      state = adapter.removeOne(state.ids.slice(-1)[0] as number, state)
    }

    return adapter.addOne(mdfe, {
      ...state,
      totalItens: state.totalItens + 1,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(addFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  })),

  /* Adicionando vários registros */
  on(addMany, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(addManySuccess, (state, { mdfes }) => {
    return adapter.addMany(mdfes, {
      ...state,
      loadingForm: false,
      totalItens: state.totalItens + mdfes.length,
      error: undefined,
    })
  }),
  on(addManyFailure, (state, { error }) => ({
    ...state,
    loadingForm: false,
    error: error,
  })),

  /* Removendo vários registros */
  on(removeMany, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(removeManySuccess, (state, { ids }) => {
    return adapter.removeMany(ids, {
      ...state,
      loadingList: false,
      totalItens: state.totalItens - ids.length,
      error: undefined,
    })
  }),
  on(removeManyFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Atualizando um registro */
  on(update, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(updateSuccess, (state, { update }) => {
    return adapter.updateOne(update, {
      ...state,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(updateFailure, (state, { error }) => ({
    ...state,
    loadingForm: false,
    error: error,
  })),

  /* Autorizando um MDFe */
  on(autorizar, (state, action) => ({
    ...state,
    loadingForm: true,
    loadingList: true,
    error: undefined,
  })),

  on(autorizarSuccess, (state, { retornoMdfe }) => {
    return adapter.map(
      (registro: Mdfe) => {
        if (retornoMdfe && retornoMdfe?.itens && retornoMdfe?.itens?.length > 0) {
          const index = retornoMdfe.itens.findIndex((v: any, index: number) => v.id === registro.id)
          if (index >= 0)
            return {
              ...registro,
              statusSefaz: retornoMdfe.itens[index].status,
              chave: retornoMdfe?.itens[index]?.chave || null,
            }
          else return registro
        } else {
          return registro
        }
      },
      { ...state, loadingForm: false, loadingList: false, error: undefined }
    )
  }),

  on(autorizarFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    loadingList: false,
    error: action.error,
  })),

  /* Cancelar um CT-e */
  on(cancelar, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(cancelarSuccess, (state, { retornoMdfe }) => {
    return adapter.map(
      (registro: Mdfe) => {
        if (retornoMdfe.itens && retornoMdfe.itens.length > 0) {
          const index = retornoMdfe.itens.findIndex((v: any, index: number) => v.id === registro.id)
          if (index >= 0 && retornoMdfe.itens[index].status == '135')
            return {
              ...registro,
              statusSefaz: '101',
            }
          else return registro
        } else {
          return registro
        }
      },
      { ...state, loadingList: false, error: undefined }
    )
  }),
  on(cancelarFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  })),

  /* Encerrar um CT-e */
  on(encerrar, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(encerrarSuccess, (state, { retornoMdfe }) => {
    return adapter.map(
      (registro: Mdfe) => {
        if (retornoMdfe.itens && retornoMdfe.itens.length > 0) {
          const index = retornoMdfe.itens.findIndex((v: any, index: number) => v.id === registro.id)
          if (index >= 0 && retornoMdfe.itens[index].status == '135')
            return {
              ...registro,
              statusSefaz: '132',
            }
          else return registro
        } else {
          return registro
        }
      },
      { ...state, loadingList: false, error: undefined }
    )
  }),
  on(encerrarFailure, (state, action) => ({
    ...state,
    loadingList: false,
    error: action.error,
  })),

  /* Criar / Atualizar e Emitir MDF-e */
  on(upsertEmitir, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(upsertEmitirSuccess, (state, { mdfe, retornoMdfe }) => {
    return adapter.upsertOne(mdfe, { ...state, loadingForm: false, error: undefined })
  }),
  on(upsertEmitirFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  })),
  /* upsert contingencia */
  on(upsertContingencia, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(upsertContingenciaSuccess, (state, { mdfe, retornoMdfe }) => {
    return adapter.upsertOne(mdfe, { ...state, loadingForm: false, error: undefined })
  }),
  on(upsertContingenciaFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  }))
)
