import { EntityState } from '@ngrx/entity'
import { Cte } from '@modules/ctes/types/ctes.types'
import { Pagination } from '@core/types'

export interface CtesState extends EntityState<Cte> {
  totalItens: number
  loadingList: boolean
  loadingForm: boolean
  pagination: Pagination
  error: string | undefined
}
