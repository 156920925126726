import { ActionReducer, INIT } from '@ngrx/store'
import { AppState } from '../app.state'

import { logout } from '../auth'

export function logoutClearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action != null && action.type === logout.type) {
      return reducer(Object.assign({ settings: state?.settings }), { type: INIT })
    }
    return reducer(state, action)
  }
}
