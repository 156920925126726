import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { ToastrService } from 'ngx-toastr'

import { of, delay } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators'

import {
  loadAll,
  loadAllFailure,
  loadAllSuccess,
  loadOne,
  loadOneSuccess,
  loadOneFailure,
  add,
  addSuccess,
  addFailure,
  update,
  updateSuccess,
  updateFailure,
  autorizar,
  upsertEmitir,
  upsertEmitirSuccess,
  upsertEmitirFailure,
  autorizarSuccess,
  autorizarFailure,
  removeMany,
  removeManySuccess,
  removeManyFailure,
  cancelar,
  cancelarSuccess,
  cancelarFailure,
  encerrar,
  encerrarSuccess,
  encerrarFailure,
  upsertContingencia,
  upsertContingenciaSuccess,
  upsertContingenciaFailure,
  addMany,
  addManyFailure,
  addManySuccess,
} from './mdfes.actions'

import { TransmissionResponse } from '@core/types/return-transmission'
import { MdfesService } from '@modules/mdfes/services/mdfes.service'
import { Mdfe } from '@modules/mdfes/mdfes.types'

@Injectable()
export class MdfesEffects {
  getAll$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadAll),
      switchMap(action =>
        this._mdfesService.getAllPaged(action.pagination).pipe(
          map(retorno => loadAllSuccess({ returnPagination: retorno })),
          catchError(error => of(loadAllFailure({ error })))
        )
      )
    )
  )

  getById$ = createEffect(() =>
    this._actions.pipe(
      ofType(loadOne),
      mergeMap(action => this._mdfesService.getById(action.mdfeId)),
      map(mdfe => loadOneSuccess({ mdfe })),
      catchError(error => of(loadOneFailure({ error })))
    )
  )

  create$ = createEffect(() =>
    this._actions.pipe(
      ofType(add),
      exhaustMap(action =>
        this._mdfesService.create(action.mdfe).pipe(
          map(mdfe => addSuccess({ mdfe })),
          catchError(error => of(addFailure({ error })))
        )
      )
    )
  )

  createSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(addSuccess),
        tap(() => {
          this._toastrService.success('Criado com sucesso!', 'MDF-e')
        })
      ),
    { dispatch: false }
  )

  createFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(addFailure),
        tap(() => {
          this._toastrService.error('Não foi possível criar. Tente novamente!', 'MDF-e')
        })
      ),
    { dispatch: false }
  )

  createMany$ = createEffect(() =>
    this._actions.pipe(
      ofType(addMany),
      exhaustMap(action =>
        this._mdfesService.createMany(action.mdfes).pipe(
          map(mdfes => addManySuccess({ mdfes })),
          catchError(error => of(addManyFailure({ error })))
        )
      )
    )
  )

  update$ = createEffect(() =>
    this._actions.pipe(
      ofType(update),
      exhaustMap(action =>
        this._mdfesService.update(action.update.id, action.update.changes).pipe(
          map(() => updateSuccess({ update: action.update })),
          catchError(error => of(updateFailure({ error })))
        )
      )
    )
  )

  updateSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(updateSuccess),
        tap(() => {
          this._toastrService.success('Atualizado com sucesso!', 'MDF-e', {
            progressBar: true,
          })
        })
      ),
    { dispatch: false }
  )

  updateFailure$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(updateFailure),
        tap(() => {
          this._toastrService.error('Não foi possível atualizar. Tente novamente!', 'MDF-e', {
            progressBar: true,
          })
        })
      ),
    { dispatch: false }
  )

  autorizar$ = createEffect(() =>
    this._actions.pipe(
      ofType(autorizar),
      exhaustMap(action =>
        this._mdfesService.autorizar(action.ids).pipe(
          map((retorno: TransmissionResponse) => {
            if (retorno?.itens && retorno?.itens[0]?.status == '100') {
              this._toastrService.success(retorno?.itens[0]?.motivo, 'MDF-e autorizado')
            } else if (retorno?.itens && retorno?.itens[0]?.status !== '100') {
              this._toastrService.error(retorno?.itens[0]?.motivo, 'MDF-e rejeitado')
            } else if (!retorno?.itens) {
              this._toastrService.error(retorno?.erro, 'MDF-e rejeitado')
            }

            return autorizarSuccess({ retornoMdfe: retorno })
          }),
          catchError(error => of(autorizarFailure({ error })))
        )
      )
    )
  )

  upsertEmitir$ = createEffect(() =>
    this._actions.pipe(
      ofType(upsertEmitir),
      exhaustMap(action =>
        this._mdfesService.upsertEmitir(action.mdfe).pipe(
          map((retorno: { objeto: Mdfe; respostas: TransmissionResponse }) => {
            if (retorno?.respostas?.itens && retorno?.respostas?.itens[0]?.status == '100') {
              this._toastrService.success(retorno?.respostas?.itens[0]?.motivo, 'MDF-e autorizado')
            } else if (retorno?.respostas?.itens && retorno?.respostas?.itens[0]?.status !== '100') {
              this._toastrService.error(retorno?.respostas?.itens[0]?.motivo, 'MDF-e rejeitado')
            } else if (!retorno?.respostas?.itens) {
              this._toastrService.error(retorno?.respostas?.erro, 'MDF-e rejeitado')
            }

            return upsertEmitirSuccess({ mdfe: retorno.objeto, retornoMdfe: retorno.respostas })
          }),
          catchError(error => of(upsertEmitirFailure({ error })))
        )
      )
    )
  )

  upsertContingencia$ = createEffect(() =>
    this._actions.pipe(
      ofType(upsertContingencia),
      delay(1000),
      exhaustMap(action =>
        this._mdfesService.upsertContingencia(action.mdfe).pipe(
          map((retorno: { objeto: Mdfe; respostas: TransmissionResponse }) => {
            if (retorno?.respostas?.itens && retorno?.respostas?.itens[0]?.status == '5') {
              this._toastrService.success(retorno?.respostas?.itens[0]?.motivo, 'MDF-e assinado')
            } else if (retorno?.respostas?.itens && retorno?.respostas?.itens[0]?.status !== '5') {
              this._toastrService.error(retorno?.respostas?.itens[0]?.motivo, 'MDF-e rejeitado')
            } else if (!retorno?.respostas?.itens) {
              this._toastrService.error(retorno?.respostas?.erro, 'MDF-e rejeitado')
            }

            return upsertContingenciaSuccess({
              mdfe: retorno.objeto,
              retornoMdfe: retorno.respostas,
            })
          }),
          catchError(error => of(upsertContingenciaFailure({ error })))
        )
      )
    )
  )

  cancelar$ = createEffect(() =>
    this._actions.pipe(
      ofType(cancelar),
      exhaustMap(action =>
        this._mdfesService.cancelar(action.objCancelamento).pipe(
          map((retorno: TransmissionResponse) => {
            if (retorno?.itens && retorno?.itens[0]?.status == '135') {
              this._toastrService.success('MDF-e CANCELADO!', 'Evento cancelamento')
            } else if (retorno?.itens && retorno?.itens[0]?.status !== '135') {
              this._toastrService.error(retorno?.itens[0]?.motivo, 'Evento cancelamento')
            }
            return cancelarSuccess({ retornoMdfe: retorno })
          }),
          catchError(error => of(cancelarFailure({ error })))
        )
      )
    )
  )

  encerrar$ = createEffect(() =>
    this._actions.pipe(
      ofType(encerrar),
      exhaustMap(action =>
        this._mdfesService.encerrar(action.objEncerramento).pipe(
          map((retorno: TransmissionResponse) => {
            if (retorno?.itens && retorno?.itens[0]?.status == '135') {
              this._toastrService.success('MDF-e ENCERRADO!', 'Evento encerramento')
            } else if (retorno?.itens && retorno?.itens[0]?.status !== '135') {
              this._toastrService.error(retorno?.itens[0]?.motivo, 'Evento encerramento')
            }
            return encerrarSuccess({ retornoMdfe: retorno })
          }),
          catchError(error => of(encerrarFailure({ error })))
        )
      )
    )
  )

  removeMany$ = createEffect(() =>
    this._actions.pipe(
      ofType(removeMany),
      exhaustMap(action =>
        this._mdfesService.removeMany(action.ids).pipe(
          map((retorno: any) => {
            let ids: Array<{ id: number }> = action.ids
            let data: Array<{ id: number; resposta: string }> = retorno
            let keys: Array<number> = []

            if (data && data.length > 0) {
              data.forEach((dataValue: { id: number; resposta: string }, i: number) => {
                if (i >= 0) {
                  this._toastrService.error(`ID: ${dataValue.id} - ${dataValue.resposta} `, 'Exclusão')
                  ids = ids.filter(idsValue => idsValue.id !== dataValue.id)
                }
              })
            }

            if (ids && ids.length > 0) {
              ids.forEach((value: { id: number }, i: number) => {
                keys.push(value.id)
              })
            }

            return removeManySuccess({ ids: keys })
          }),
          catchError(error => of(removeManyFailure({ error })))
        )
      )
    )
  )

  removeSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(removeManySuccess),
        tap((data: { ids: number[] }) => {
          if (data.ids.length > 0)
            this._toastrService.success(`Os registros ${data.ids} foram excluidos com sucesso!`, 'Exclusão')
        })
      ),
    { dispatch: false }
  )
  constructor(private _actions: Actions, private _mdfesService: MdfesService, private _toastrService: ToastrService) {}
}
