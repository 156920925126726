import { Component, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SwUpdate } from '@angular/service-worker'
import { AppState } from '@core/root-store'
import { selectTheme } from '@core/root-store/settings'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

@Component({
  selector: 'st-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  theme$: Observable<string>

  constructor(private store: Store<AppState>, private swUpdate: SwUpdate, private snackBar: MatSnackBar) {
    this.theme$ = store.select(selectTheme)
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(versionEvent => {
        if (versionEvent.type == 'VERSION_READY') {
          const snack = this.snackBar.open('Atualização disponível', 'Recarregar', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
          })

          snack.onAction().subscribe(() => {
            this.swUpdate.activateUpdate().then(() => document.location.reload())
          })
        }
      })
    }
  }
}
