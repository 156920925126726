import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { Store } from '@ngrx/store'
import { AppState, fromAuth } from '@core/root-store'

import { Observable, of } from 'rxjs'
import { map, mergeMap, switchMap, take } from 'rxjs/operators'

import { AuthService } from '@core/services'
import { loginAuth } from '@core/types'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    /* Verifica primeiramente a store e depois a API, caso nenhuma das duas derem certo, logout */
    return this.checkStoreAuthentication().pipe(
      mergeMap(isAuthenticated => {
        if (isAuthenticated) {
          return of(true)
        }

        return this.checkApiAuthentication()
      }),
      map(storeOrApiAuth => {
        if (!storeOrApiAuth) {
          this.store.dispatch(fromAuth.logout())
          return false
        }

        return true
      })
    )
  }

  /* Recupera a informação de autenticação da store */
  checkStoreAuthentication() {
    return this.store.select(fromAuth.selectIsAuthenticated).pipe(take(1))
  }

  /* Tenta fazer o refresh com as informação que tem no localStorage */
  checkApiAuthentication() {
    if (!this.authService.getRefreshToken()) {
      return of(false)
    }

    return this.authService.refreshToken().pipe(
      switchMap((data: loginAuth): Observable<boolean> => {
        this.store.dispatch(fromAuth.refreshTokenSuccess({ loginAuth: data }))
        return of(!!data)
      })
    )
  }
}
