import { EntityState } from '@ngrx/entity'
import { Pagination } from '@core/types/pagination'
import { Veiculo } from '@modules/veiculos/veiculos.types'

export interface VeiculosState extends EntityState<Veiculo> {
  totalItens: number
  loadingList: boolean
  loadingForm: boolean
  pagination: Pagination
  error: string | undefined
}
