import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'

import {
  add,
  addSuccess,
  addFailure,
  loadAll,
  loadAllSuccess,
  loadAllFailure,
  loadOne,
  loadOneSuccess,
  loadOneFailure,
  update,
  updateSuccess,
  updateFailure,
  remove,
  removeSuccess,
  removeFailure,
  removeMany,
  removeManySuccess,
  removeManyFailure,
} from './parceiros.actions'

import { ParceirosState } from './parceiros.state'
import { ColumnsPage } from '@core/types'
import { Parceiro } from '@modules/parceiros/parceiros.types'
import { updateColumns } from '.'

export const adapter: EntityAdapter<Parceiro> = createEntityAdapter<Parceiro>()

export const initialColumns: ColumnsPage[] = [
  { name: 'nome', label: 'Nome', show: true },
  { name: 'fantasia', label: 'Fantasia', show: false },
  { name: 'cpf', label: 'CPF', show: true },
  { name: 'rg', label: 'RG', show: false },
  { name: 'cnpj', label: 'CNPJ', show: true },
  { name: 'inscricaoestadual', label: 'Inscrição Estadual', show: true },
  { name: 'inscricaomunicipal', label: 'Inscrição Municipal', show: false },
  { name: 'datanascimento', label: 'Data Nascimento', show: false },
  { name: 'regimetributario', label: 'Regime Tributário', show: false },
  { name: 'sexo', label: 'Sexo', show: false },
  { name: 'situacao', label: 'Situação', show: true },
]

export const initialState: ParceirosState = adapter.getInitialState({
  totalItens: 0,
  loadingForm: false,
  loadingList: false,
  pagination: {
    sort: 'id',
    order: 'desc',
    page: 0,
    limit: 50,
    columns: initialColumns,
    arraySearch: [],
    arraySearchAdvanced: [],
  },
  error: undefined,
})

export const reducer = createReducer(
  initialState,
  /* Carregamento dos registros */
  on(loadAll, (state, action) => ({
    ...state,
    loadingList: true,
    pagination: { ...state.pagination, ...action.pagination },
    error: undefined,
  })),
  on(loadAllSuccess, (state, { returnPagination }) => {
    return adapter.setAll(returnPagination.itens, {
      ...state,
      totalItens: returnPagination.quantidadeRegistros,
      loadingList: false,
      error: undefined,
    })
  }),
  on(loadAllFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Carregamento de um registro */
  on(loadOne, (state, action) => ({
    ...state,
    error: undefined,
  })),
  on(loadOneSuccess, (state, { parceiro }) => {
    return adapter.setOne(parceiro, {
      ...state,
      error: undefined,
    })
  }),
  on(loadOneFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  /* Adicionando um registro na primeira posição da lista*/
  on(add, (state, action) => ({ ...state, loadingForm: true, error: undefined })),
  on(addSuccess, (state, { parceiro }) => {
    if (state.totalItens >= state.pagination.limit) {
      state = adapter.removeOne(state.ids.slice(-1)[0] as number, state)
    }

    return adapter.addOne(parceiro, {
      ...state,
      totalItens: state.totalItens + 1,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(addFailure, (state, action) => ({
    ...state,
    loadingForm: false,
    error: action.error,
  })),

  /* Removendo um registro */
  on(remove, (state, action) => ({
    ...state,
    error: undefined,
  })),
  on(removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      error: undefined,
    })
  }),
  on(removeFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  /* Removendo vários registros */
  on(removeMany, (state, action) => ({
    ...state,
    loadingList: true,
    error: undefined,
  })),
  on(removeManySuccess, (state, { ids }) => {
    return adapter.removeMany(ids, {
      ...state,
      loadingList: false,
      totalItens: state.totalItens - ids.length,
      error: undefined,
    })
  }),
  on(removeManyFailure, (state, { error }) => ({
    ...state,
    loadingList: false,
    error: error,
  })),

  /* Atualizando um registro */
  on(update, (state, action) => ({
    ...state,
    loadingForm: true,
    error: undefined,
  })),
  on(updateSuccess, (state, { update }) => {
    return adapter.updateOne(update, {
      ...state,
      loadingForm: false,
      error: undefined,
    })
  }),
  on(updateFailure, (state, { error }) => ({
    ...state,
    loadingForm: false,
    error: error,
  })),
  on(updateColumns, (state, { columns }) => ({
    ...state,
    pagination: { ...state.pagination, ...{ columns } },
  }))
)
